/**
 * My Account SCSS
 */

.my-account-main-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  background-color: #F6F6F6;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .my-account-wrap {
    display: inline-block;
    width: 100%;
    .sidebar {
      float: left;
      display: inline-block;
      width: 29%;
      margin-right: 30px;
      @media (max-width: 992px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
      .img-wrap {
        position: relative;
        display: inline-block;
        width: 100%;
        border: 2px solid $dark-grey;
        border-radius: 2px;
        img {
          width: 100%;
        }
        span {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          display: inline-block;
          width: 100%;
          padding: 12px;
          background-color: $dark-grey;
          color: $white;
          font-size: 15px;
          font-weight: 600;
          font-family: 'Rubik', sans-serif;
          text-align: left;
          text-transform: uppercase;
          input {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 100%;
            cursor: pointer;
            opacity: 0;
          }
        }
      }
      .tab-listing {
        margin-top: 25px;
        padding: 10px;
        background-color: $white;
        ul {
          display: inline-block;
          width: 100%;
          li {
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid $border-grey;
            border-radius: 5px;
            &:last-child {
              border-bottom: none;
            }
            a {
              display: inline-block;
              width: 100%;
              padding: 12px 10px;
              border-radius: 5px;
              color: #878787;
              &:hover,
              &.active {
                background-color: $dark-grey;
                color: $white;
              }
              i {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .content-wrap {
      float: left;
      @include css3-sufix(width, calc(71% - 30px));
      padding: 20px 20px 30px;
      background-color: $white;
      @media (max-width: 992px) {
        width: 100%;
      }
      h2 {
        margin-bottom: 20px;
        color: $primary-color;
        font-size: 20px;
        font-family: 'Rubik', sans-serif;
        span {
          color: $base-color;
        }
        img {
          width: 15px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
      .tab-content {
        display: none;
        width: 100%;
        &.active {
          display: inline-block;
        }
      }
      .my-profile {
        .profile-detail-form {
          display: inline-block;
          width: 100%;
          form {
            .form-item {
              margin-bottom: 20px;
            }
          }
        }
        .personal-info {
          margin-bottom: 30px;
          .form-item {
            float: left;
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 15px;
            text-align: left;
            @include css3-sufix(width, calc(50% - 15px));
            &:nth-child(2n) {
              margin-right: 0;
            }
            @media (max-width: 767px) {
              width: 100%;
              margin-right: 0;
            }
          }
        }
        .street-zip-info {
          .form-item {
            float: left;
            display: inline-block;
            width: 100%;
            &:first-child {
              @include css3-sufix(width, calc(70% - 30px));
              margin-right: 30px;
              @media (max-width: 767px) {
                width: 100%;
                margin-right: 0;
              }
            }
            &:last-child {
              width: 30%;
              @media (max-width: 767px) {
                width: 100%;
              }
            }
          }
        }
      }
      .tracking-order {
        padding: 20px 0 0;
        .bs-wizard-step {
          padding: 0 25px;
          @include css3-sufix(display, flex);
          .step-1,
          .step-2 {
            @include css3-sufix(width, calc(50% - 22.5px));
            @media (max-width: 767px) {
              @include css3-sufix(width, calc(50% - 12px));
            }
          }
          .step {
            @include css3-sufix(display, flex);
            @include css3-prefix(align-items, center);
            .bs-wizard-dot {
              position: relative;
              height: 45px;
              width: 45px;
              background-image: url('../images/Ellipse-grey.png');
              background-position: center;
              background-size: 100% auto;
              @media (max-width: 767px) {
                height: 24px;
                width: 24px;
              }
            }
            .progress {
              height: auto;
              margin: 0 -2px;
              @include css3-sufix(width, calc(100% - 41px));
              border-radius: 0;
              background-color: transparent;
              @media (max-width: 767px) {
                @include css3-sufix(width, calc(100% - 20px));
              }
            }
            .progress-bar {
              height: 10px;
              width: 100%;
              background-color: $dark-grey;
              @media (max-width: 767px) {
                height: 5px;
              }
            }
            &.complete {
              .progress-bar {
                background-color: $green-light;
              }
            }
            &.complete,
            &.active {
              .bs-wizard-dot {
                background-image: url('../images/Ellipse.png');
              }
            }
          }
        }
        .bs-wizard-step-content {
          margin-top: 20px;
          @include css3-sufix(display, flex);
          @include css3-prefix(justify-content, space-between);
          .step {
            display: inline-block;
            text-align: center;
            opacity: .3;
            &.complete,
            &.active {
              opacity: 1;
            }
            .step-text {
              margin-bottom: 5px;
              font-size: 19px;
              font-family: 'Rubik', sans-serif;
              @media (max-width: 767px) {
                font-size: 13px;
              }
            }
            .info {
              margin-bottom: 20px;
              color: $primary-color;
              font-size: 20px;
              font-weight: 600;
              @media (max-width: 767px) {
                font-size: 13px;
              }
            }
            img {
              max-width: 100%;
              @media (max-width: 767px) {
                max-width: 40%;
              }
            }
          }
          .step-3 {
            img {
              max-width: 95px;
              @media (max-width: 767px) {
                max-width: 40px;
              }
            }
          }
        }
        .bs-wizard-step-detail-text {
          display: inline-block;
          width: 100%;
          margin-top: 30px;
          text-align: center;
          .step {
            display: none;
            &.active {
              display: block;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .subscribed-services {
        .grid {
          float: left;
          @include css3-sufix(width, calc(33.33% - 10px));
          margin-right: 15px;
          margin-bottom: 15px;
          @media (min-width: 992px) {
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
          @media (max-width: 992px) {
            @include css3-sufix(width, calc(50% - 10px));
            margin-right: 20px;
            margin-bottom: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
          }
          .content-wrap {
            display: inline-block;
            width: 100%;
            padding: 20px 15px;
            @media (max-width: 992px) {
              padding: 10px 0 0;
            }
            h3 {
              margin-bottom: 0;
              color: #282828;
              font-size: 15px;
              line-height: normal;
              text-align: center;
              text-transform: uppercase;
              @include css3-prefix(transition, .3s all);
            }
          }
        }
      }
      .service-tickets,
      #invite {
        b {
          a {
            color: $base-color;
          }
        }
      }
    }
  }
}
.account-table {
  table {
    min-width: 700px;
    th,
    td {
      padding: 7px 5px;
    }
    thead {
      border-bottom: 2px solid $green-light;
      th {
        color: $primary-color;
        font-weight: 500;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $border-grey;
        &:nth-child(2n) {
          background-color: #F5F5F5;
        }
      }
      td {
        font-size: 14px;
        &.price {
          text-align: center;
        }
      }
      td.name {
        width: 288px;
        a {
          color: $primary-color;
        }
        button {
          @extend .btn;
          min-width: 0;
          padding: 6px 12px;
          border-radius: 2px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .start-date {
        span {
          display: none;
        }
      }
      .status {
        &.placed {
          color: $green-light;
        }
        &.not-started {
          color: $light-brown;
        }
        &.completed {
          color: $red-dark;
        }
      }
    }
  }
}

