
/**
 * Slider SCSS
 */

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  height: $circle-size;
  width: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: $item-size;
    width: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: -90;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}
.functional-process-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 80px 0 30px;
  .carousel {
    position: relative;
    z-index: 3;
    display: inline-block;
    width: 100%;
    margin-top: 100px;
    padding: 50px 0;
    @media (max-width: 767px) {
      display: none;
      margin-top: 0;
    }
    .carousel-indicators {
      display: block;
      margin: 0 auto;
      @media (max-width: 1200px) and (min-width: 768px){
        @include on-circle($item-count: 9, $circle-size: 550px, $item-size: 78px);
        @include css3-prefix(transform, scale(.95));
      }
      @media (min-width: 768px){
        position: absolute !important;
        bottom: 115px;
        @include on-circle($item-count: 9, $circle-size: 550px, $item-size: 78px);
      }
      @media (max-width: 767px) {
        top: 0;
        @include css3-sufix(display, flex);
        @include css3-prefix(justify-content, space-between);
      }
      li {
        @include css3-sufix(display, flex);
        @include css3-prefix(justify-content, center);
        @include css3-prefix(align-items, center);
        border: 2px solid $grey-c;
        border-radius: 100%;
        background-color: $white;
        text-align: center;
        cursor: pointer;
        @media (max-width: 767px) {
          display: none;
          height: 80px;
          width: 80px;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            @include css3-sufix(display, inline-flex);
          }
        }
        .img-red {
          display: block;
        }
        .img-white {
          display: none;
        }
        img {
          display: inherit;
          width: 30px;
        }
        &.active {
          border-color: transparent;
          @include background-diagonal-tl-br($base-color, #E63342, #D7333D, $red-dark);
          background-color: $base-color;
          .img-red {
            display: none;
          }
          .img-white {
            display: block;
          }
        }
      }
    }
    .carousel-indicators2 {
      position: absolute;
      top: 0;
      height: 40px;
      margin-top: -100px;
      margin-bottom: 150px;
      padding: 0 60px;
      @media (max-width: 767px) {
        display: none;
      }
      li {
        display: inline-block;
        padding: 7px;
        border: 1px dashed transparent;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        @media (max-width: 767px) {
          display: none;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            display: inline-block;
          }
        }
        &.active {
          border: 1px dashed $red-dark;
          color: $red-dark;
        }
      }
    }
    .carousel-indicators3 {
      @media (max-width: 1200px) and (min-width: 768px){
        @include on-circle($item-count: 9, $circle-size: 690px, $item-size: 90px);
        position: absolute;
        top: auto;
        bottom: -38px;
        @include css3-prefix(transform, translateX(-50%) scale(.95));
      }
      @media (min-width: 768px){
        position: absolute;
        top: auto;
        bottom: -48px;
        left: 50%;
        @include css3-prefix(transform, translateX(-50%));
        @include on-circle($item-count: 9, $circle-size: 690px, $item-size: 110px);
      }
      @media (max-width: 767px) {
        @include css3-sufix(display, flex);
        @include css3-prefix(justify-content, space-between);
        margin-top: 50px;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        height: 560px;
        width: 560px;
        margin-top: -38px;
        background-image: url('../images/path.png');
        background-size: cover;
        content: '';
        @include css3-prefix(transform, translate(-50%, -50%));
        @media (max-width: 1200px) and (min-width: 768px){
          height: 550px;
          width: 550px;
          margin-top: -40px;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      li {
        height: auto;
        color: #878787;
        text-align: center;
        cursor: pointer;
        @media (max-width: 767px) {
          display: none;
          width: 90px;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            display: inline-block;
          }
        }
        &:nth-child(2) {
          @media (min-width: 768px) {
            margin-left: -5px;
            text-align: left;
          }
        }
        &:nth-child(3) {
          @media (min-width: 768px) {
            margin-left: -40px;
            text-align: right;
          }
        }
        &:nth-child(4) {
          margin-left: -45px;
        }
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          text-align: left;
        }
        @media (max-width: 1200px) and (min-width: 768px){
          &:nth-child(4) {
            margin-left: -32px;
          }
          &:nth-child(8) {
            margin-left: -60px;
          }
        }
        /*&:nth-child(7) {
          width: 100px;
        }*/
      }
    }
    .carousel-inner {
      width: 300px;
      margin: 0 auto;
      text-align: center;
      @media (min-width: 768px) {
        position: absolute;
        top: 48%;
        left: 50%;
        width: 450px;
        @include css3-prefix(transform, translate(-50%, -50%));
      }
      .carousel-item {
        opacity: 0;
        @include css3-prefix(transition, opacity .3s ease);
        .slider {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          > img {
            max-width: 200px;
            /*&:nth-child(2) {
              @include css3-prefix('transform' , 'translateY(-100%)');
              @include css3-prefix('transition' , 'transform .7s ease 1s, opacity .3s ease .7s');
            }
            &:nth-child(3) {
              @include css3-prefix('transform' , 'translateY(100%)');
              @include css3-prefix('transition' , 'transform .7s ease 1.7s, opacity .3s ease 1.5s');
            }*/
          }
          &.registration {
            position: relative;
            display: inline-block;
            min-height: 150px;
            width: 100%;
            .reg-1 {
              position: absolute;
              left: 0;
              display: inline-block;
              width: 150px;
              @include css3-prefix('transform' , 'translateX(-100%)');
              @include css3-prefix('transition' , 'transform .5s ease .3s');
              .reg-2 {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                margin: -6px auto auto 2px;
                opacity: 0;
                @include css3-prefix('transform' , 'translate(-50% , -50%)');
                @include css3-prefix('transition' , 'opacity .5s ease 1s');
              }
            }
            .reg-3,
            .reg-4 {
              position: absolute;
              left: 150px;
              display: inline-block;
              width: 150px;
              opacity: 0;
              @include css3-prefix('transition' , 'opacity .5s ease 1.5s');
            }
            .reg-4 {
              @include css3-prefix('transform' , 'translateX(0)');
              @include css3-prefix('transition' , 'transform .7s ease 2s , opacity .3s ease 1.9s');
            }
          }
        }
        .text {
          position: relative;
          z-index: 999;
          margin-top: 20px;
          opacity: 0;
          @include css3-prefix('transition' , 'opacity 1s ease .3s');
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            display: inline-block;
            height: 100%;
            width: 100%;
            background-image: url('../images/cloud-chat.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
          }
          p {
            position: relative;
            z-index: 10;
            height: 220px;
            width: 300px;
            margin: 0 auto 30px;
            padding: 35px;
            font-size: 16px;
            @include css3-sufix('display' , 'inline-flex');
            @include css3-prefix('align-items' , 'center');
          }
        }
        &.active {
          opacity: 1;
          .text {
            opacity: 1;
          }
          .slider {
            > img {
              opacity: 0;
              &:nth-child(1) {
                /*margin-top: 5px;*/
                @include css3-prefix('transform' , 'translateX(-100%)');
                @include css3-prefix('animation' , 'from-left 6s ease 0s');
              }
              &:nth-child(2) {
                @include css3-prefix('animation' , 'from-right 4s ease 1s');
                @include css3-prefix('transform' , 'translateY(-100%)');
                /*margin-top: 40px;*/
                /*margin-left: 45px;*/
              }
              &:nth-child(3) {
                @include css3-prefix('transform' , 'translateY(100%)');
                @include css3-prefix('animation' , 'from-bottom 2s ease 2s');
                /*margin-top: 10px;*/
                /*margin-right: -15px;*/
              }
            }
            &.registration {
              .reg-1 {
                @include css3-prefix('transform' , 'translateX(0)');
                .reg-2 {
                  opacity: 1;
                }
              }
              .reg-3 {
                opacity: 1;
              }
              .reg-4 {
                opacity: 1;
                @include css3-prefix('transform' , 'translateX(100%)');
              }
            }
            @include keyframes('from-left') {
              0% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateX(-100%)');
              }
              20% {
                margin-top: 5px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              80% {
                margin-top: 5px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              100% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateX(-100%)');
              }
            }
            @include keyframes('from-right') {
              0% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateY(-100%)');
              }
              20% {
                margin-top: 40px;
                margin-left: 45px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              80% {
                margin-top: 40px;
                margin-left: 45px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              100% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateY(-100%)');
              }
            }
            @include keyframes('from-bottom') {
              0% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateY(100%)');
              }
              20% {
                margin-top: 10px;
                margin-right: -15px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              80% {
                margin-top: 10px;
                margin-right: -15px;
                opacity: 1;
                @include css3-prefix('transform' , translate(0));
              }
              100% {
                opacity: 0;
                @include css3-prefix('transform' , 'translateY(100%)');
              }
            }
            @include keyframes('typing') {
              0% { width: 0; }
              100% { width: 100%; }
            }
            /* The typewriter cursor effect */
            @include keyframes('blink-caret') {
              0%, 100% { border-color: transparent; }
              50% { border-color: orange; }
            }
          }
        }
      }
    }
  }
  .cloud-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .cloud-1 {
      position: absolute;
      max-width: 200px;
      width: 100%;
      margin-top: 50%;
      @include css3-prefix('animation' , 'to-fro 30s linear 0s infinite');
    }
    .cloud-2 {
      position: absolute;
      max-width: 150px;
      width: 100%;
      margin-top: 30%;
      @include css3-prefix('animation' , 'to-fro 50s linear 0s backwards infinite');
    }
  }
}

@include keyframes('to-fro') {
  0% {
    @include css3-prefix('transform' , 'translateX(-100%)');
  }
  100% {
    @include css3-prefix('transform' , 'translateX(110vw)');
  }
}

/*
 * Mobile slider Design
 */
@mixin half-circle($item-count, $circle-size, $item-size) {
  position: relative;
  height: $circle-size;
  width: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: $item-size;
    width: $item-size;
    margin: -($item-size / 2);

    $angle: (180 / $item-count);
    $rot: -90;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}
.functional-process-wrap-mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
  .flexslider {
    margin-bottom: 0;
  }
  .flex-direction-nav {
    display: none;
  }
  #functional-process-mobile-slider {
    li {
      text-align: center;
      .text {
        position: relative;
        z-index: 999;
        display: inline-block;
        margin-top: 20px;
        @include css3-prefix('transition' , 'opacity 1s ease .3s');
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          display: inline-block;
          height: 100%;
          width: 100%;
          background-image: url('../images/cloud-chat.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
        }
        p {
          position: relative;
          z-index: 10;
          height: 210px;
          max-width: 300px;
          margin: 0 auto;
          padding: 35px;
          font-size: 16px;
          @include css3-sufix('display' , 'inline-flex');
          @include css3-prefix('align-items' , 'center');
        }
      }
    }
  }
  #functional-process-mobile-slider-thumb {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
      background-image: url('../images/half-circle.png');
      background-position: top 22px center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      content: '';
      @media (max-width: 494px) {
        background-position: top 45px center;
      }
    }
    .owl-item {
      @include css3-prefix(transform, translate3d(0, 100px, 30px));
      @include css3-prefix(transition, .3s all);
    }
    .owl-item.active {
      @include css3-prefix(transform, translate3d(0, 50px, 30px));
      + .owl-item.active {
        padding-bottom: 50px;
        @include css3-prefix(transform, translate3d(0, 0, 0));
        + .owl-item.active {
          padding-bottom: 50px;
          @include css3-prefix(transform, translate3d(0, 0, 0));
          + .owl-item.active {
            @include css3-prefix(transform, translate3d(0, 50px, 30px));
          }
        }
      }
      @media (max-width: 494px) {
          @include css3-prefix(transform, translate3d(0, 50px, 30px));
        + .owl-item.active {
          padding-bottom: 50px;
          @include css3-prefix(transform, translate3d(0, 0, 0));
          + .owl-item.active {
            @include css3-prefix(transform, translate3d(0, 50px, 30px));
          }
        }
      }
    }
    li {
      text-align: center;
      .img {
        @include css3-sufix(display, flex);
        @include css3-prefix(justify-content, center);
        @include css3-prefix(align-items, center);
        height: 90px;
        width: 90px;
        margin: 0 auto;
        border: 2px solid $grey-c;
        border-radius: 100%;
        background-color: $white;
        text-align: center;
        cursor: pointer;
        .img-red {
          display: block;
        }
        .img-white {
          display: none;
        }
        img {
          display: inherit;
          width: 30px;
        }
      }
      .tag-name {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
      }
    }
    .synced {
      .img {
        border-color: transparent;
        @include background-diagonal-tl-br($base-color, #E63342, #D7333D, $red-dark);
        background-color: $base-color;
        .img-red {
          display: none;
        }
        .img-white {
          display: block;
        }
      }
    }
  }
}

