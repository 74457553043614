/**
 * Services SCSS
 */

/* Services page Design */
.product-list-page {
  .banner-wrap {
    position: relative;
    background-image: none !important;
    .img-wrap {
      float: left;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      @media (max-width: 1024px) {
        @include css3-sufix(display, flex);
        @include css3-prefix(flex-wrap, wrap);
        @include css3-prefix(justify-content, center);
      }
      /*@media (max-width: 992px) {
        height: 0;
        width: 0;
        opacity: 0;
      }*/
      img {
        /*@include css3-prefix(filter, blur(2px));*/
        @media (max-width: 1024px) {
          height: 300px;
          max-width: initial;
          width: initial;
        }
        /*@media (max-width: 992px) {
          height: 0;
          width: 0;
          opacity: 0;
        }*/
      }
    }
    .search-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      @include css3-prefix(transform, translate(-50%, -50%));
      h1,
      p {
        color: $black;
      }
    }
  }
}

.banner-overlay {
  .banner-wrap {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(208, 155, 67, .86);
      content: '';
    }
    .search-wrap {
      h1,
      p {
        color: $white;
      }
    }
  }
}

.services-main-wrap,
.services-inner-detail-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
}
.services-main-wrap {
  .grids {
    display: inline-block;
    width: 100%;
    text-align: center;
    .grid {
      display: inline-block;
      @include css3-sufix(width, calc(33.33% - 24px));
      margin-right: 32px;
      margin-bottom: 40px;
      @include css3-prefix(box-shadow, 0 0 15px rgba(0, 0, 0, .2));
      @include css3-prefix(border-radius, 3px);
      @include css3-prefix(transition, .3s all);
      text-align: center;
      vertical-align: top;
      &:last-child {
        margin-right: 0;
      }
      @media (min-width: 992px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix(width, calc(50% - 22px));
        margin-bottom: 40px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media (max-width: 640px) {
        width: 100%;
        margin-right: 0;
      }
      .img-wrap {
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          height: 100%;
          width: 100%;
          @include background-diagonal-tl-br(#727272, #727272, $base-color, $base-color);
          @include css3-prefix(transition, .3s all);
          content: '';
          opacity: .7;
        }
        img {
          width: 100%;
        }
      }
      .content-wrap {
        display: inline-block;
        width: 100%;
        padding: 20px 15px;
        h3,
        h2 {
          margin-bottom: 15px;
          color: #282828;
          font-size: 17px;
          line-height: normal;
          text-transform: uppercase;
          @include css3-prefix(transition, .3s all);
        }
        h2 {
          font-size: 14px;
        }
        p {
          margin-bottom: 0;
          color: #696969;
          font-size: 15px;
          line-height: 28px;
          font-family: 'Rubik', sans-serif;
        }
      }
      &:hover {
        margin-top: -30px;
        margin-bottom: 70px;
        .img-wrap::after {
          display: block;
        }
        .content-wrap {
          h3 {
            color: $base-color;
          }
        }
      }
    }
  }
}
/* End Services page Design */

/* Services inner page 1 Design */
.professional-service-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .grids {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    .grid {
      float: left;
      @include css3-sufix(width, calc(33.33% - 27px));
      margin-right: 40px;
      text-align: center;
      @media (min-width: 992px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix(width, calc(50% - 20px));
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media (max-width: 640px) {
        width: 100%;
        margin-right: 0;
      }
      .img-wrap {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
      h3,
      p {
        display: inline-block;
        max-width: 80%;
        @media (max-width: 480px) {
          max-width: 100%;
        }
      }
      h3 {
        margin-bottom: 15px;
        color: #282828;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      p {
        display: inline-block;
        color: #696969;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
}
.offer-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .grids {
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    .grid {
      .img-wrap {
        position: relative;
        float: left;
        /*display: inline-block;*/
        width: 350px;
        margin-right: 40px;
        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }
        .img-slide {
          a {
            cursor: zoom-in;
          }
        }
        img {
          width: 100%;
          border: 1px solid #E0DDDD;
        }
        /*&::after {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          height: 100%;
          width: 100%;
          @include background-diagonal-img-effect(rgba($white, .3), rgba($white, .3), transparent, transparent);
          content: '';
        }*/
      }
      .content-wrap {
        /*float: left;*/
        /*display: inline-block;*/
        /*@include css3-sufix(width, calc(100% - 400px));*/
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      h3 {
        margin-bottom: 15px;
        color: #282828;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
      }
      .product-price {
        display: inline-block;
        margin-top: 15px;
        margin-right: 15px;
        padding: 9px 20px;
        background-color: $base-color;
        color: $white;
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;
        vertical-align: middle;
      }
      .cart-button {
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
        overflow: hidden;
        vertical-align: middle;
        a {
          padding: 8px 12px;
          font-size: 14px;
        }
      }
      p {
        /*display: inline-block;*/
        margin-bottom: 30px;
        color: #696969;
        font-size: 14px;
        /*line-height: 28px;*/
      }
      .wrapper {
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        .btn-wrap {
          display: inline-block;
          height: 65px;
          margin-right: 10px;
          margin-bottom: 10px;
          overflow: hidden;
          @media (max-width: 767px) {
            height: auto;
          }
          @media (max-width: 480px) {
            float: left;
            width: 48%;
            margin-right: 4%;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
      .paymeny-type {
        margin-bottom: 20px;
        img {
          margin-right: 20px;
          vertical-align: bottom;
        }
      }
      .btn {
        padding: 12px 20px;
        overflow: visible;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Rubik', sans-serif;
        text-transform: unset;
        @include css3-prefix(border-radius, 0);
        img {
          max-width: 65px;
          margin-top: -12px;
          margin-bottom: -28px;
        }
        @media (max-width: 767px) {
          padding: 12px;
          font-size: 16px;
          img {
            display: none;
          }
        }
        @media (max-width: 480px) {
          min-width: 0;
          width: 100%;
        }
      }
    }
  }/*
  .product-slider {

  }*/
}
/* End Services inner page 2 Design */

/* Services inner page 1 Design */
.services-inner-wrap {
  display: inline-block;
  width: 100%;
  padding: 60px 0;
  color: #333;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .services-wraps {
    display: inline-block;
    width: 100%;
    margin-top: 60px;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
    .service-wrap {
      h3 {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        padding: 10px 20px;
        color: $white;
        /*font-size: 30px;*/
        font-size: 22px;
        font-weight: 700;
        @media (max-width: 992px){
          font-size: 20px;
        }
      }
      .grids {
        width: 100%;
        @include css3-sufix('display' , 'flex');
        @include css3-prefix('flex-wrap' , 'wrap');
        @include css3-prefix('justify-content' , 'center');
        a {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          color: #282828;
          font-size: 16px;
          font-weight: 700;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p {
          margin-bottom: 0;
          color: #696969;
          font-size: 14px;
          line-height: 25px;
        }
        .grid {
          display: inline-block;
          @include css3-sufix(width, calc(33.33% - 23.5px));
          margin-right: 35px;
          margin-bottom: 40px;
          &:last-child {
            margin-right: 0;
          }
          @media (min-width: 992px) {
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
          @media (max-width: 992px){
            @include css3-sufix(width, calc(50% - 17.5px));
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
          }
          .image-wrap {
            display: inline-block;
            width: 80px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
          .content {
            display: inline-block;
            /*width: 100%;*/
            @include css3-sufix(width, calc(100% - 95px));
            margin-top: -5px;
            /*padding-left: 15px;*/
            vertical-align: top;
          }
        }
      }
      .bg-grey + .grids {
        margin-bottom: 35px;
      }
    }
  }
}
/* End Services inner page 2 Design */

/* Bundle product page design */
.bundle-sidebar {
  float: left;
  display: inline-block;
  width: 30%;
  padding-right: 30px;
  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0;
  }
}
.bundle-detail-content {
  float: left;
  display: inline-block;
  width: 70%;
  @media (max-width: 992px) {
    width: 100%;
  }
}
.bundle-product-title {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
  @include css3-prefix(transition, .5s all);
  .image-wrap {
    display: inline-block;
    max-width: 300px;
    width: 100%;
    margin-bottom: 10px;
  }
  .product-price {
    display: inline-block;
    width: auto;
    padding: 9px 20px;
    background-color: $base-color;
    color: $white;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 500;
    vertical-align: middle;
  }
  .paymeny-type {
    margin: 20px 0 0;
    img {
      margin-right: 20px;
      vertical-align: bottom;
    }
  }
  .section-title {
    text-align: left;
  }
  .description {
    margin-bottom: 20px;
  }
  &.sticky-title {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 10px 0;
    background-color: $white;
    @include css3-prefix(box-shadow, 1px 0 7px 3px rgba($black, .15));
    h2 {
      margin-bottom: 0;
      text-align: center;
    }
    .product-price {
      position: absolute;
      top: 6px;
      right: 4%;
    }
    .description,
    .paymeny-type {
      display: none;
    }
  }
}
.bundle-products-wrap {
  display: inline-block;
  width: 100%;
  .bundle-products-grid {
    padding: 15px 0;
    border-bottom: 1px solid rgba($black, .2);
    &:last-child {
      margin-bottom: 20px;
    }
    .product-wrap {
      position: relative;
      display: inline-block;
      width: 100%;
      .checkbox-wrap {
        display: inline-block;
        width: 32px;
        height: 33px;
        vertical-align: top;
        @media (max-width: 640px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 33px;
          padding-left: 4px;
          padding-top: 3px;
          background-color: rgba($black, .6);
        }
        .input-rc input[type='checkbox'],
        .input-rc input[type='checkbox'] + .input-rc-span {
          height: 26px;
          width: 24px;
        }
      }
      .image-wrap {
        display: inline-block;
        width: 100px;
        margin-right: 10px;
        vertical-align: top;
        @media (max-width: 640px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
      .bundle-product-detail {
        display: inline-block;
        @include css3-sufix(width, calc(100% - 150px));
        @media (max-width: 640px) {
          width: 100%;
          margin-top: 0;
        }
        .product-title {
          display: inline-block;
          @include css3-sufix(width, calc(100% - 250px));
          vertical-align: top;
          @media (max-width: 640px) {
            width: 100%;
          }
          h3 {
            word-break: break-word;
            cursor: pointer;
          }
        }
        .product-price {
          max-width: 245px;
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 5px;
          @media (max-width: 640px) {
            margin-bottom: 15px;
          }
          .original-price {
            padding-right: 10px;
            opacity: .7;
            text-decoration: line-through;
          }
        }
        .short-description {
          > p {
            margin-bottom: 0;
            .view-more {
              padding: 0 5px;
              color: $base-color;
              &:hover {
                color: $base-color;
                text-decoration: underline;
              }
            }
          }
        }
        .product-description {
          display: none;
          margin-top: 15px;
        }
      }
    }
  }
}
.bundle-products-details {
  .add-to-cart-wrap {
    overflow: hidden;
    text-align: right;
  }
}
.bundle-main-wrap {
  .service-wrap {
    .content {
      .product-price {
        display: inline-block;
        width: auto;
        margin-top: 10px;
        margin-right: 0;
        padding: 9px 20px;
        background-color: $base-color;
        color: $white;
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;
        vertical-align: middle;
      }
    }
  }
}
/* End Bundle product page design */

