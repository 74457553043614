/**
 * Home SCSS
 */

/* Banner Section design */
.banner-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid $border-color;
  text-align: center;
  .search-wrap {
    display: inline-block;
    max-width: 1000px;
    width: 100%;
    padding: 50px 15px;
    text-align: center;
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
    h1 {
      color: $primary-color;
      /*color: #272729;*/
      /*font-size: 66px;*/
      font-size: 30px;
      line-height: 35px;
      font-weight: 700;
      font-family: 'Rubik', sans-serif;
      /*@media (max-width: 1024px) {
        font-size: 50px;
      }*/
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    p {
      margin-bottom: 0;
      color: $primary-color;
      /*font-size: 20px;*/
      font-size: 16px;
      font-weight: 300;
      font-family: 'Rubik', sans-serif;
    }
    form {
      display: inline-block;
      width: 100%;
      margin-top: 15px;
      padding: 5px;
      border: 1px solid $grey-c;
      background-color: $white;
      @include css3-prefix(border-radius, 10px);
      @media (max-width: 640px) {
        padding: 20px 15px;
      }
      .form-item,
      .form-action {
        display: inline-block;
        @media (max-width: 640px) {
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
      .form-item {
        @media (max-width: 640px) {
          margin-bottom: 20px;
        }
      }
      .form-select {
        width: 170px;
        border-right: 1px solid #DEE0DF;
        @media (max-width: 640px) {
          width: 100%;
          border-right: none;
        }
        select {
          height: 44px;
          width: 100%;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 35px;
          border: none;
          /*color: #4E4E4E;*/
          color: $primary-color;
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          @media (max-width: 640px) {
            border: 1px solid $border-grey;
          }
        }
      }
      .form-input {
        @include css3-sufix(width, calc(100% - 300px));
        padding-left: 26px;
        @media (max-width: 640px) {
          width: 100%;
          padding-left: 0;
        }
        input {
          width: 100%;
          border: none;
          font-size: 14px;
          font-weight: 500;
          &:focus {
            border: none;
          }
          @media (max-width: 640px) {
            border: 1px solid $border-grey;
            &:focus {
              border: 1px solid $border-grey;
            }
          }
        }
      }
    }
  }
}
.home-page {
  .banner-wrap {
    /*background-image: url('../images/home-banner.jpg');*/
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    /*@media (max-width: 1600px) {
      background-size: cover;
    }*/
    .img-wrap,
    .img-wrap img {
      height: 0;
      width: 0;
      opacity: 0;
    }
    .search-wrap {
      max-width: 700px;
      padding: 180px 15px;
      @media (max-width: 1600px){
        padding: 80px 15px;
      }
      @media (max-width: 1024px){
        padding: 50px 15px;
      }
      h1,
      p {
        color: $primary-color;
      }
    }
  }
}

 /* End Banner section design */

/* Services section Design */
.services-wrap {
  display: inline-block;
  width: 100%;
  padding: 70px 0 0;
  @media (max-width: 1024px){
    padding: 40px 0 0;
  }
  .services-items-wrap {
    float: left;
    display: inline-block;
    height: 160px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .services-items {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    width: 100%;
    @media (max-width: 1024px){
      width: max-content;
      padding: 0 50px 20px;
    }
    li {
      position: relative;
      float: left;
      display: inline-block;
      width: 20%;
      padding: 0 15px;
      border-right: 1px solid $grey-c;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      &::after {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 50%;
        display: none;
        margin: 0 0 -63px -20px;
        border-right: 30px solid transparent;
        border-bottom: 30px solid #DDDFDE;
        border-left: 30px solid transparent;
        content: '';
      }
      .img-black {
        display: block;
        margin: 0 auto 20px;
      }
      .img-red {
        display: none;
      }
      &.active {
        &::after {
          display: inline-block;
          @media (max-width: 1024px) {
            display: none;
          }
        }
        .img-red {
          display: block;
          margin: 0 auto 20px;
        }
        .img-black {
          display: none;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        img {
          display: inline-block;
          width: auto;
          margin-bottom: 20px;
        }
        span {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          color: $primary-color;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media (max-width: 1200px){
            font-size: 13px;
          }
        }
      }
    }
  }
  .service-content {
    position: relative;
    float: left;
    display: inline-block;
    width: 100%;
    padding: 50px;
    overflow: hidden;
    background-color: #DDDFDE;
    @media (max-width: 992px){
      margin-top: 25px;
      padding: 25px;
    }
    .service-detail-wrap {
      position: absolute;
      top: 0;
      right: -100%;
      display: inline-block;
      width: 100%;
      opacity: 0;
      @include css3-prefix(transition, all .7s);
      &.active {
        position: relative;
        right: 0;
        opacity: 1;
      }
      .welcome-text {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        span {
          position: relative;
          z-index: 1;
          display: inline-block;
          padding-right: 50px;
          background-color: #DDDFDE;
          font-size: 16px;
          text-transform: uppercase;
          @media (max-width: 480px) {
            padding-right: 0;
          }
        }
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: $grey-c;
          content: '';
          @media (max-width: 480px) {
            display: none;
          }
        }
      }
      .img-wrap,
      .detail-wrap {
        float: left;
        width: 50%;
        @media (max-width: 992px){
          width: 100%;
        }
      }
      .detail-wrap {
        margin-bottom: 30px;
        padding-right: 50px;
        @media (max-width: 992px){
          padding-right: 0;
        }
        .service-name {
          display: inline-block;
          width: 100%;
          margin-bottom: 20px;
          color: $primary-color;
          font-size: 30px;
          line-height: 40px;
          /*@media (max-width: 992px){
            font-size: 32px;
          }*/
          @media (max-width: 767px){
            font-size: 28px;
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .img-wrap {
        float: right;
        @media (max-width: 992px){
          margin: 0 0 40px;
        }
        img {
          @include css3-prefix(box-shadow, -19px -19px 0 $white);
          @media (max-width: 992px){
            @include css3-prefix(box-shadow, 19px 19px 0 $white);
          }
        }
      }
    }
  }
}
/* End Services section Design */

/*subscribe section*/
.subscribe-wrap {
  display: inline-block;
  width: 100%;
  padding: 40px 0;
  background-color: #EAEAEA;
  text-align: center;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .subscribe {
    width: 100%;
    padding: 0 30px;
    @include css3-sufix('display' , 'flex');
    @include css3-prefix('justify-content' , 'center');
    @include css3-prefix('align-items' , 'center');
    @media (max-width: 1200px) {
      @include css3-prefix('flex-direction' , 'column');
    }
    span {
      display: inline-block;
      margin-right: 25px;
      font-size: 30px;
      font-weight: 700;
      @media (max-width: 1200px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    form {
      display: inline-block;
      max-width: 660px;
      width: 100%;
      margin-bottom: 0;
      @media (max-width: 1400px) {
        max-width: 560px;
      }
      input {
        display: inline-block;
        height: 42px;
        max-width: 500px;
        width: 100%;
        padding: 0 88px 0 20px;
        border: 1px solid #A0A0A0;
        border-radius: 10px;
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        vertical-align: top;
        outline: none;
        box-shadow: none;
        @include input-placeholder {
          color: #575757;
          font-weight: 500;
        }
        @media (max-width: 1400px) {
          max-width: 400px;
        }
        @media(max-width: 767px) {
          max-width: 300px;
        }
        @media (max-width: 480px) {
          max-width: 500px;
          width: 100%;
          margin-bottom: 15px;
        }
      }
      button {
        margin-left: -70px;
        padding: 9px 20px;
        @media (min-width: 481px) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
        @media (max-width: 480px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

/*blog section*/
.blog-wrap {
  display: inline-block;
  width: 100%;
  padding: 70px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  .title-caption {
    margin-bottom: 40px;
  }
  .grids {
    .grid {
      float: left;
      margin-right: 60px;
      @include css3-sufix('width' , 'calc(50% - 30px)');
      &:nth-child(2n) {
        margin-right: 0;
      }
      @media (max-width: 992px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .single-blog {
        width: 100%;
        overflow: hidden;
        @include css3-sufix('display' , 'flex');
        @media (max-width: 480px) {
          flex-direction: column;
        }
        .img-wrap {
          float: left;
          display: inline-block;
          width: 50%;
          img {
            min-height: 100%;
            max-width: initial;
            @media (max-width: 992px) and (min-width: 767px){
              width: 100%;
            }
          }
          @media (max-width: 480px) {
            width: 100%;
            margin-bottom: 19px;
            text-align: center;
            img {
              @include css3-prefix(box-shadow, 19px 19px 0 $base-color);
            }
          }
        }
        .content-wrap {
          position: relative;
          float: left;
          display: inline-block;
          width: 50%;
          padding: 20px 20px 50px;
          background-color: #F7F7F7;
          @media (max-width: 480px) {
            width: 100%;
          }
          h4 {
            font-size: 16px;
            line-height: 28px;
            font-weight: 600;
          }
          .date,
          .location {
            color: $primary-color;
            font-size: 12px;
          }
          .location {
            position: relative;
            margin-right: 5px;
            padding-right: 10px;
            &::after {
              position: absolute;
              top: 0;
              right: 0;
              display: inline-block;
              content: '-';
            }
          }
          p {
            margin: 10px 0;
            color: $primary-color;
            font-size: 14px;
            line-height: 24px;
          }
          .author-wrap {
            @include css3-sufix('display' , 'inline-flex');
            @include css3-prefix('align-items' , 'center');
            @include css3-prefix('justify-content' , 'center');
            .author-img {
              display: inline-block;
              height: 50px;
              width: 50px;
              margin-right: 10px;
              overflow: hidden;
              border-radius: 50%;
            }
            span {
              font-size: 14px;
            }
          }
          .read-more {
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: #646464;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.blog-wrap-main {
  &.blog-wrap {
    .grids {
      .grid {
        margin-bottom: 60px;
      }
    }
  }
}

/*testimonial section */
.testimonial-wrap {
  padding: 60px 0 90px;
  background-image: url('../images/testimonial-bg.jpg');
  background-size: cover;
  text-align: center;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  .img-wrap {
    display: block;
    height: 80px;
    width: 80px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
  }
  .testimonial-title {
    margin: 20px 0;
    color: $primary-color;
    font-size: 30px;
    text-transform: uppercase;
  }
  .testimonail-details {
    display: block;
    max-width: 900px;
    margin: 0 auto 30px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 35px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
  .owl-dots {
    bottom: -55px;
    left: 0;
  }
}

/*package section*/
.package-wrap {
  display: inline-block;
  width: 100%;
  padding: 60px 0;
  overflow: hidden;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  .grids {
    position: relative;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    @include css3-sufix('display' , 'flex');
    @include css3-prefix('flex-wrap' , 'wrap');
    @include css3-prefix('align-items' , 'flex-start');
    @media (min-width: 992px) {
      padding-top: 40px;
    }
    &::before,
    &::after {
      position: absolute;
      top: 200px;
      display: inline-block;
      height: 100px;
      width: 350px;
      background-repeat: no-repeat;
      content: '';
    }
    &::before {
      left: -400px;
      background-image: url('../images/package-bg-1.png');
    }
    &::after {
      right: -400px;
      background-image: url('../images/package-bg-2.png');
    }
    .grid {
      float: left;
      @include css3-sufix('display' , 'flex');
      @include css3-prefix('flex-wrap' , 'wrap');
      margin-right: 40px;
      border: 5px solid transparent;
      border-radius: 18px;
      @include css3-sufix('width' , 'calc(33.33% - 27.3333px)');
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        border: 5px solid $base-color;
      }
      &.blue {
        &:hover {
          border-color: #4964AB;
        }
      }
      &.green {
        @media (min-width: 992px) {
          margin-top: -40px;
        }
      }
      &.red {
        &:hover {
          border-color: #A26E30;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix('width' , 'calc(50% - 20px)');
        margin-bottom: 30px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(3n) {
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .single-package {
        position: relative;
        width: 100%;
        padding: 40px 20px 75px;
        border: 1px solid $grey-c;
        border-radius: 18px;
        text-align: center;
        &:hover {
          border: 1px solid transparent;
        }
        .banner-rd {
          position: relative;
          z-index: 1;
          display: none;
          @include css3-sufix(width, calc(100% + 66px));
          margin: 0 -33px 20px;
          padding: 5px 0;
          color: $white;
          font-size: 22px;
          text-transform: uppercase;
          @include background-diagonal-tl-br($base-color, #E63342, #D7333D, $red-dark);
          &::before,
          &::after {
            position: absolute;
            top: -12px;
            display: inline-block;
            height: 0;
            width: 0;
            content: '';
          }
          &::before {
            left: 0;
            border-bottom: 12px solid #C6C7C8;
            border-left: 12px solid transparent;
          }
          &::after {
            right: 0;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #C6C7C8;
          }
          &:hover {
            @include background-diagonal-tl-br($red-dark, #D7333D, #E63342, $base-color);
          }
          &.btn-blue {
            @include background-diagonal-tl-br(#5D8AC4, #5C88C1, #4E6FB3, #4964AB);
            &:hover {
              @include background-diagonal-tl-br(#4964AB, #4E6FB3, #5C88C1, #5D8AC4);
            }
          }
          &.btn-red {
            @include background-diagonal-tl-br(#AB6029, #A96329, #A26E30, #9D7735);
            &:hover {
              @include background-diagonal-tl-br(#AB6029, #A96329, #A26E30, #9D7735);
            }
          }
        }
        .title {
          font-size: 20px;
          text-transform: uppercase;
        }
        ul {
          margin-bottom: 15px;
          li {
            position: relative;
            display: inline-block;
            width: 100%;
            margin: 5px 0;
            padding-left: 30px;
            color: #A0A0A0;
            font-size: 14px;
            font-weight: 300;
            font-family: 'Rubik', sans-serif;
            text-align: left;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              height: 18px;
              width: 18px;
              margin: 2px 0;
              background-image: url('../images/tick-grey.png');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100%;
              content: '';
            }
          }
        }
        .price {
          position: relative;
          display: inline-block;
          width: 100%;
          margin-bottom: 10px;
          color: #686868;
          font-size: 40px;
          font-weight: 700;
          span {
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: -10px;
            background-image: url('../images/rupee-indian.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            font-size: 0;
            vertical-align: text-top;
          }
        }
        &.recommended {
          .banner-rd {
            display: inline-block;
          }
        }
        .btn {
          position: absolute;
          top: auto;
          bottom: 30px;
          left: 50%;
          @include css3-prefix(transform, translateX(-50%));
        }
      }
    }
  }
}
