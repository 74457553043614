/**
 * About us SCSS
 */

.our-services-wrap-main {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .provide-detail-text {
    display: inline-block;
    width: 100%;
    margin: 50px 0;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    .provide-text {
      float: left;
      display: inline-block;
      width: 40%;
      margin-right: 4%;
      color: $primary-color;
      font-size: 19px;
      font-weight: 600;
      text-align: right;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        text-align: left;
      }
      span {
        color: $base-color;
      }
    }
    .detail-text {
      float: left;
      display: inline-block;
      width: 56%;
      font-size: 14px;
      line-height: 25px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .grids {
    .grid {
      float: left;
      @include css3-sufix(width, calc(33.33% - 27px));
      margin-right: 40px;
      @media (min-width: 992px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix(width, calc(50% - 10px));
        margin-right: 20px;
        margin-bottom: 30px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media (max-width: 640px) {
        width: 100%;
        margin-right: 0;
      }
      .img-wrap {
        float: left;
        display: inline-block;
        width: 60px;
      }
      .content {
        float: left;
        display: inline-block;
        @include css3-sufix(width, calc(100% - 60px));
      }
      h3 {
        max-width: 80%;
        margin-bottom: 15px;
        color: $primary-color;
        font-size: 16px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
      p {
        display: inline-block;
        max-width: 80%;
        font-size: 14px;
        line-height: 26px;
        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
    }
  }
}

.checkout-services-wrap {
  display: inline-block;
  width: 100%;
  padding: 40px 0 80px;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .checkout-services {
    @include css3-sufix(display, flex);
    @include css3-sufix(flex-wrap, wrap);
    @include css3-prefix(align-items, center);
    @include css3-prefix(flex-direction, row-reverse);
    width: 100%;
  }
  .content-wrap {
    display: inline-block;
    width: 50%;
    vertical-align: middle;
    @media (max-width: 767px) {
      width: 100%;
    }
    h3 {
      color: $primary-color;
      font-size: 26px;
      line-height: 36px;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    p {
      margin-bottom: 25px;
      font-size: 15px;
      line-height: 28px;
    }
  }
  .img-wrap {
    display: inline-block;
    width: 49%;
    padding-left: 50px;
    text-align: center;
    vertical-align: middle;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0;
    }
  }
}
.book-now-wrap {
  display: inline-block;
  width: 100%;
  padding: 25px 0;
  background-color: $base-color;
  p {
    display: inline-block;
    margin-bottom: 0;
    padding-right: 20px;
    @include css3-sufix(width, calc(100% - 220px));
    color: $white;
    font-size: 26px;
    vertical-align: middle;
    @media (max-width: 992px) {
      font-size: 20px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;
      padding-right: 0;
    }
  }
}
