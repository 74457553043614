/**
 * FAQ SCSS
 */

.faq-content-main {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .faq-wrap-main {
    float: left;
    display: inline-block;
    @include css3-sufix(width, calc(70% - 50px));
    margin-right: 50px;
    @media (max-width: 992px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    h2 {
      display: inline-block;
      width: 100%;
      margin: 0 0 15px;
      color: $primary-color;
      font-size: 22px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
    }
    .faq-item {
      display: inline-block;
      width: 100%;
      margin-bottom: 25px;
      .question-wrap {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 10px 20px 10px 40px;
        background-color: #F4F4F4;
        color: $primary-color;
        font-size: 17px;
        font-weight: 600;
        cursor: pointer;
        @media (max-width: 480px) {
          font-size: 15px;
        }
        .plus-icon {
          position: absolute;
          top: 50%;
          left: 0;
          display: inline-block;
          height: 16px;
          width: 16px;
          margin: -8px 0 0 10px;
          background-image: url('../images/add.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          font-size: 0;
        }
        &.active {
          background-color: $base-color;
          color: $white;
          .plus-icon {
            background-image: url('../images/remove.svg');
          }
        }
      }
      .ans-wrap {
        display: none;
        padding: 30px 10px 15px 40px;
        @media (max-width: 767px) {
          padding: 20px 10px 15px 20px;
        }
      }
    }
    .residential-content {
      display: inline-block;
      width: 100%;
      margin-bottom: 70px;
      padding-bottom: 60px;
      border-bottom: 1px solid $border-grey;
      @media (max-width: 992px) {
        margin-bottom: 20px;
        padding-bottom: 30px;
      }
    }
    .faq-content:first-of-type {
      .faq-item {
        .ans-wrap {
          display: block;
        }
      }
    }
  }
  .req-form-main {
    float: left;
    display: inline-block;
    width: 30%;
    @media (max-width: 992px) {
      width: 100%;
    }
    .req-form {
      display: inline-block;
      width: 100%;
      padding: 60px 34px;
      background-color: $base-color;
      color: $white;
      @media (max-width: 480px) {
        padding: 20px;
      }
      h3 {
        margin: 0 0 25px;
        color: $white;
        font-size: 26px;
        text-transform: uppercase;
        @media (max-width: 480px) {
          font-size: 20px;
        }
      }
      .form-item {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        input {
          border: none;
        }
        .text-danger {
          color: $white !important;
        }
      }
      .form-action {
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}
