/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */



/* ---- Colors ---- */
$white: #FFF;
$black: #000;

$base-color: #EE3345; /* Red color */
// $base-color:    #BECC51; /* Green color */
/*$primary-color: #3A3A3A;  Font color */
$primary-color: #707070; /* Font color */

$brown:     #CE9353;
$blue:      #005FC1;
$green:     #55A22C;
$red:       #E10028;
$dark-grey: #4E4E4E;
$orange:    #DD6D2E;

$red-dark: #D0333B;
$green-light: #BDC54C;

$dark-grey: #3D3D3D;
$light-brown: #D09B43;

$blue: #004571;
$light-blue: #008ACC;

$top-header-text: #A2BED4;

$footer-text: #647B92;
$base-font-color: $primary-color;

$button-bg-color: #9ABEC7;
$button-color: $white;

$grey-c: #CCC;
$bg-grey: #F2F2F2;

$border-color: #EAEAEA;
$border-grey: #C8C8C8;

/* ---- Label Colors ---- */
$label-red: #DB211E;
$label-green: #0BBE30;
$label-gray: #7E8C95;
$label-yellow: #FCD741;


/* ---- Text ---- */

// Font Family
$body-font: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
$heading-font: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
$alt-heading-font: 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif;
$alt-heading-font1: 'Fira Sans', 'Helvetica Neue', Helvetica, sans-serif;

// Text Sizes
$base-font-size: 16px;
$base-weight: 400;
$line-hight: 1;
$letter-spacing: .2px;

// Heading Sizes
$heading-base-size: 30px;
$heading-weight: 400;

// Text Color
$base-text-color: #777;
$heading-text-color: $black;
$link-color: rgba($base-text-color, 1);



/* ---- White Space and widths---- */
$ws-xs: 50px;
$ws-s: 65px;
$ws-m: 100px;
$ws-l: 160px;

/* ---- Various Container widths ---- */
$container-s: 50%;
$container-m: 56%;

/* ---- Buttons ---- */
$btn-padding: 12px 38px;
$btn-font-size: .9em;
$btn-letter-spaceing: 2.5px;
$btn-line-height: 1.8em;
$btn-rounded: 0;
$btn-trans-time: .5s;
$btn-font-weight: 600;

/* ---- Forms ---- */
$input-height: 40px;
$input-border-color: #DDD;
$input-form-control-margin-bottom: 25px;


/* ---- Navigation ---- */
$navbar-higth: 75px;
$navbar-background: $white;
$nav-links-font-size: .8em;
$nav-links-letter-spacing: 2px;


// Dropdown
$dropdown-padding: 20px 0 10px;
$dropdown-background: rgba($white, .93);
$dropdown-link-padding: 11px 40px 11px 25px;
$dropdown-link-background: rgba(17, 17, 17, .05);
$dropdown-link-shift: 35px; // no shift on 25px;
$dropdown-header-padding: 0 25px;
$dropdown-header-font-size: .8em;
$dropdown-divider-color: #DDD;


/* ---- Footer ---- */
$footer-bg: #E3E3E3;
$footer-color: #111;
$footer-copyright-bg: #222;
$footer-hr-color: #999;
