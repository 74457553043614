/**
 * Header SCSS
 */

header {
  position: relative;
  float: left;
  z-index: 9;
  display: inline-block;
  width: 100%;
  @include css3-prefix('box-shadow' , '0 0 50px 0 rgba(240, 240, 240, 1)');
  .top-header {
    float: left;
    display: inline-block;
    width: 100%;
    padding: 11px 0 12px;
    border-bottom: 1px solid #EAEAEA;
    background-color: #1D2D3C;
    font-family: 'Rubik', sans-serif;
    @media (max-width: 767px) {
      padding: 2px 0 4px;
    }
    .support-wrap {
      float: left;
      display: inline-block;
      width: 40%;
      padding: 3px 0;
      @media (max-width: 767px) {
        width: 60%;
      }
      @media (max-width: 420px) {
        width: 100%;
      }
      li {
        display: inline-block;
        margin-right: 5px;
        padding-right: 5px;
        border-right: 1px solid rgba(40, 40, 40, .8);
        line-height: 14px;
        &:last-child {
          border-right: none;
        }
        a {
          /*color: rgba(40, 40, 40, .8);*/
          color: $white;
          font-size: 14px;
          font-family: 'Rubik', sans-serif;
          text-transform: uppercase;
          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
    }
    .right-wrap {
      float: left;
      display: inline-block;
      width: 60%;
      text-align: right;
      @media (max-width: 767px) {
        width: 40%;
      }
      @media (max-width: 420px) {
        width: 100%;
        text-align: left;
      }
      .contacts {
        display: inline-block;
        li {
          display: inline-block;
          padding: 0 10px;
          @media (max-width: 767px) {
            padding: 0 10px 0 0;
          }
          a,
          span {
            /*color: rgba(40, 40, 40, .8);*/
            color: $white;
            font-size: 15px;
            @media (max-width: 1024px) {
              font-size: 12px;
            }
          }
          span {
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
      .social-icon {
        display: inline-block;
        vertical-align: middle;
        @media (max-width: 1024px) {
          display: none;
        }
        li {
          display: inline-block;
          a {
            display: inline-block;
            height: 15px;
            width: 15px;
            margin: 5px 8px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            img {
              display: none;
            }
            &.twitter {
              background-image: url('../images/twitter.svg');
              &:hover {
                background-image: url('../images/twitter-hover.svg');
              }
            }
            &.facebook {
              background-image: url('../images/facebook.svg');
              &:hover {
                background-image: url('../images/facebook-hover.svg');
              }
            }
            &.youtube {
              background-image: url('../images/youtube.svg');
              &:hover {
                background-image: url('../images/youtube-hover.svg');
              }
            }
            &.instagram {
              background-image: url('../images/instagram.svg');
              &:hover {
                background-image: url('../images/instagram-hover.svg');
              }
            }
            &.g-plus {
              height: 21px;
              width: 21px;
              padding: 0 8px;
              background-image: url('../images/google-plus.svg');
              &:hover {
                background-image: url('../images/google-plus-hover.svg');
              }
            }
            &.linkedin {
              background-image: url('../images/linkedin.svg');
              &:hover {
                background-image: url('../images/linkedin-hover.svg');
              }
            }
          }
          img {
            width: 15px;
          }
        }
      }
    }
  }
  .bottom-header {
    float: left;
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    @media (max-width: 1024px) {
      padding: 7px 0 0;
    }
    .nav-wrap {
      display: inline-block;
      width: 100%;
      .navbar-brand {
        float: left;
        width: 200px;
        margin-right: 0;
        @media (max-width: 1200px) {
          width: 150px;
        }
        @media (max-width: 767px) {
          width: 100px;
        }
        a {
          display: inline-block;
          max-width: 170px;
          width: 100%;
        }
      }
      .nav-bar {
        display: inline-block;
        padding: 12px 0 2px;
        @include css3-sufix(width, calc(100% - 200px));
        text-align: right;
        @media (max-width: 1200px) {
          @include css3-sufix(width, calc(100% - 150px));
        }
        @media (min-width: 1025px) {
          display: inline-block !important;
        }
        @media (max-width: 1024px) {
          position: absolute;
          top: 135px;
          left: 0;
          z-index: 11;
          display: none;
          width: 100%;
          padding: 0;
          border-top: 1px solid $blue;
          background-color: $white;
          text-align: left;
        }
        @media (max-width: 767px) {
          top: 114px;
        }
        .navbar-nav {
          > ul {
            > li {
              position: relative;
              display: inline-block;
              margin-left: 15px;
              padding: 7px 12px;
              @media (max-width: 1024px) {
                width: 100%;
                margin-left: 0;
                padding: 0;
                border-bottom: 1px solid $blue;
              }
              &.sub-menu {
                padding: 0;
                a {
                  border: none;
                  &:hover,
                  &.active {
                    border: none;
                    &::after {
                      display: none;
                    }
                  }
                }
                > a {
                  @media (max-width: 1024px) {
                    padding-right: 35px;
                  }
                }
                > span {
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: auto;
                  height: 100%;
                  width: 30px;
                  background-image: url('../images/arrow-down.svg');
                  background-position: top 10px center;
                  background-repeat: no-repeat;
                  background-size: 15px auto;
                  font-size: 0;
                  cursor: pointer;
                  @media (min-width: 1025px) {
                    display: none;
                  }
                }
                .dropdown-toggle::after {
                  display: none;
                }
              }
              a {
                position: relative;
                display: inline-block;
                padding-bottom: 15px;
                border-bottom: 2px solid transparent;
                color: #696969;
                font-size: 16px;
                font-family: 'Rubik', sans-serif;
                @media (min-width: 1025px) {
                  &:hover,
                  &.active {
                    border-bottom: 2px solid $base-color;
                    &::after {
                      position: absolute;
                      top: auto;
                      bottom: 0;
                      left: 50%;
                      height: 0;
                      width: 0;
                      margin-left: -2px;
                      border-right: 4px solid transparent;
                      border-bottom: 4px solid $base-color;
                      border-left: 4px solid transparent;
                      content: '';
                    }
                  }
                }
                @media (max-width: 1024px) {
                  width: 100%;
                  padding: 7px 12px;
                }
              }
              @media (min-width: 1025px) {
                &:hover {
                  .submenu {
                    display: inline-block;
                  }
                }
                &:last-child {
                  padding-bottom: 16px;
                  a {
                    padding: 7px 16px;
                    border: none;
                    background-color: $base-color;
                    color: $white;
                    text-transform: uppercase;
                    @include css3-prefix(border-radius, 4px);
                    &::after {
                      display: none;
                    }
                    &:hover {
                      opacity: .9;
                    }
                  }
                  .submenu {
                    a {
                      background-color: transparent;
                      color: #696969;
                      text-transform: unset;
                    }
                  }
                }
              }
              .submenu {
                display: none;
                @media (min-width: 1025px) {
                  position: absolute;
                  top: 40px;
                  left: 0;
                  z-index: 9;
                  max-width: max-content;
                  min-width: min-content;
                  width: 100%;
                  padding: 10px;
                  background-color: $white;
                  @include css3-prefix(box-shadow, 0 4px 7px rgba(0, 0, 0, .5));
                }
                &::before {
                  @media (min-width: 1025px) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: none;
                    height: 0;
                    width: 0;
                    margin: -15px 0 0 15px;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid $white;
                    border-left: 15px solid transparent;
                    content: '';
                  }
                }
                li {
                  list-style: none;
                  @media (min-width: 1025px) {
                    width: max-content;
                    padding: 7px 0;
                  }
                  @media (max-width: 1024px) {
                    padding: 0 0 0 15px;
                  }
                  a {
                    border-bottom: none;
                    font-size: 14px;
                    @media (min-width: 1025px) {
                      margin-right: 10px;
                      @include css3-prefix('transition' , '.5s ease');
                    }
                    &::after {
                      display: none;
                    }
                    @media (min-width: 1025px) {
                      padding: 6px;
                      &:hover {
                        margin-right: 0;
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
              &.cart {
                a {
                  position: relative;
                  &:hover {
                    border-bottom: none;
                    &::after {
                      display: none;
                    }
                  }
                  @media (max-width: 1024px) {
                    width: auto;
                  }
                  span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: auto;
                    height: 20px;
                    width: 20px;
                    margin-top: -9px;
                    margin-right: -12px;
                    border-radius: 100%;
                    background-color: $base-color;
                    color: $white;
                    font-size: 12px;
                    line-height: 19px;
                    text-align: center;
                    @media (max-width: 1024px) {
                      margin-top: -2px;
                      margin-right: -4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .toggle-button {
    float: right;
    display: none;
    height: 30px;
    width: 30px;
    margin: 22px 0;
    border: none;
    background-color: transparent;
    background-image: url('../images/menu.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
    @media (max-width: 1024px) {
      display: inline-block;
    }
    @media (max-width: 767px) {
      margin: 8px 0;
    }
  }
}
