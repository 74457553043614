/**
 * Typography SCSS
 */

.section-title {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  /*color: #333;*/
  color: $primary-color;
  /*font-size: 44px;*/
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.title-caption {
  @include push--auto;
  max-width: 780px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: $primary-color;
  /*font-size: 19px;*/
  font-size: 16px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.list-icon {
  li {
    position: relative;
    padding-left: 36px;
    line-height: 36px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      height: 24px;
      width: 22px;
      margin-top: 8px;
      background-image: url('../images/list-icon.png');
      background-repeat: no-repeat;
      background-size: 100% auto;
      content: '';
    }
  }
}
