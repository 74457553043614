/**
 * Components SCSS
 *
 */

.bg-brown {
  background-color: $brown;
}
.bg-blue {
  background-color: $blue;
}
.bg-green {
  background-color: $green;
}
.bg-red {
  background-color: $red;
}
.bg-grey {
  background-color: $dark-grey;
}
.bg-orange {
  background-color: $orange;
}
.bg-light-green {
  background-color: $green-light;
}
.bg-light-grey {
  background-color: #F7F7F7;
}

.bg-pattern {
  background-image: url('../images/bg-pattern.png');
  background-size: 100% auto;
}

.alert {
  p {
    margin-bottom: 0;
  }
}

/* Subscribe popup design */
#subscribe_model {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 510px;
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 30px;
  }
  .modal-content {
    border: 6px solid $white;
    border-radius: 0;
  }
  .modal-body {
    padding: 0;
    .subscribe {
      @include css3-prefix(flex-wrap, wrap);
      span {
        width: 100%;
        margin-bottom: 20px;
      }
      form {
        width: 100%;
        input {
          float: left;
          margin-bottom: 15px;
          padding-right: 20px;
          @media (min-width: 480px) {
            @include css3-sufix(width, calc(100% - 127px));
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .btn {
          max-width: 128px;
          margin-left: -1px;
          @media (min-width: 480px) {
            float: left;
          }
        }
      }
    }
  }
}
/* End Subscribe popup design */


/* Pagination design */
.pagination {
  .page-item {
    .page-link {
      color: $base-color;
      &:focus {
        @include css3-prefix(box-shadow, none);
      }
    }
    &.active {
      .page-link {
        border-color: $base-color;
        background-color: $base-color;
        color: $white;
      }
    }
    &.disabled {
      .page-link {
        color: $dark-grey;
      }
    }
  }
}
/* End Pagination design */

/* Slider dots */
.owl-pagination {
  position: absolute;
  top: auto;
  bottom: -40px;
  left: 0;
  z-index: 99;
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
  .owl-page {
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 10px;
    background-color: rgba($blue, .5);
    @include css3-prefix(border-radius, 100%);
    &.active {
      background-color: $blue;
    }
  }
}
/* End Slider dots */

.product-slider {
  .owl-pagination {
    bottom: 20px;
  }
}

/* Carousel nav Design */
.owl-controls {
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    height: 30px;
    width: 30px;
    margin-top: -15px;
    background-image: url('../images/right-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    font-size: 0;
  }
  .owl-prev {
    left: 0;
    @include css3-prefix(transform, rotate(-180deg));
  }
  .owl-next {
    right: 0;
  }
}
/* Carousel nav Design */

#tawkchat-minified-box {
  @include css3-prefix(transform, scale(1));
  #tawkchat-minified-wrapper {
    right: 0;
  }
}

.page-not-found {
  display: inline-block;
  width: 100%;
  padding: 50px 0;
  text-align: center;
  .error-img {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    h1 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      color: #1D2D3C;
      font-size: 54px;
      font-style: italic;
      @include css3-prefix(transform, translateY(-50%));
      @media (max-width: 480px) {
        font-size: 34px;
      }
    }
  }
  span {
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
    color: #1D2D3C;
    font-size: 45px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 38px;
    }
  }
  h2 {
    margin-bottom: 20px;
    color: #1D2D3C;
    font-size: 26px;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
}
