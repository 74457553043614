/**
 * Footer SCSS
 */

footer {
  .footer-wrap {
    padding: 20px 0;
    background-color: #1D2D3C;
    .grids {
      display: inline-block;
      width: 100%;
      padding: 20px 0 50px;
      @media (max-width: 767px) {
        padding: 20px 0;
      }
      .grid {
        @include grid($column: 'col-4');
        margin-bottom: 15px;
        .single-column {
          color: $white;
          font-size: 14px;
          line-height: 26px;
          text-align: left;
          a {
            color: $white;
          }
          .logo-wrap {
            display: inline-block;
            margin-bottom: 20px;
          }
          .footer-title {
            display: inline-block;
            width: 100%;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
          }
          .phone-wrap {
            span {
              display: inline-block;
              width: 100%;
            }
          }
          ul {
            display: inline-block;
            width: 100%;
            li {
              display: inline-block;
              width: 100%;
            }
          }
          .social-icon {
            margin-bottom: 30px;
            span {
              display: inline-block;
              margin: 0 15px 0 0;
              a {
                display: inline-block;
                color: #919AA8;
                font-size: 20px;
                &:hover {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
    .copyright {
      padding: 20px 0;
      border-top: 1px solid rgba($white, .2);
      border-bottom: 1px solid rgba($white, .2);
      color: $white;
      font-size: 14px;
      span {
        color: $base-color;
      }
      + p {
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
        padding: 10px 0;
        a {
          color: #919AA8;
        }
      }
    }
  }
}

