/**
* Form design
*/

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
textarea,
select,
.resizable-textarea textarea,
.form-control {
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid $border-grey;
  background-color: $white;
  color: #8C969B;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  &:focus {
    @include css3-prefix(box-shadow, none);
  }
}
input {
  &:focus {
    @include css3-prefix(box-shadow, none);
    outline: none;
  }
}
.form-item {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='tel'],
  textarea,
  select,
  .resizable-textarea textarea,
  .form-control {
    @include css3-prefix(border-radius, 0);
    @include css3-prefix(box-sizing, border-box);
    @include css3-prefix(box-shadow, none);
    @include input-placeholder {
      color: #8C969B;
      font-size: 15px;
    }
    &:focus {
      outline: none;
      &:not([readonly]) {
        border: 1px solid $border-grey;
        background-color: $white;
        outline: none;
        @include css3-prefix(box-shadow, none);
      }
    }
  }
  &.even {
    margin-right: 0;
  }
}
form {
  .form-item {
    &.even {
      margin-right: 0 !important;
    }
  }
}
label {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.form-item {
  select {
    padding: 5px 35px 5px 10px;
    @include css3-prefix(appearance, none);
    background-image: url('../images/arrow-down.svg');
    background-position: center right 20px;
    background-repeat: no-repeat;
    background-size: 10px auto;
  }
}
textarea {
  min-height: 110px;
  width: 100%;
}
.btn {
  position: relative;
  min-width: 120px;
  padding: 8px 20px;
  @include background-diagonal-tl-br($base-color, #E63342, #D7333D, $red-dark);
  overflow: hidden;
  border: none;
  border-radius: 5px;
  color: $white;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  @include css3-prefix('transition' , 'all 2s ease 0s');
  &:hover {
    color: $white;
    @include background-diagonal-tl-br($red-dark, #D7333D, #E63342, $base-color);
    &::before {
      @include css3-prefix('animation' , 'shine .75s');
    }
  }
  &:focus {
    outline: none;
    @include css3-prefix(box-shadow, none);
  }
  @media (max-width: 767px) {
    padding: 6px 15px;
  }
  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    height: 100%;
    width: 50%;
    content: '';
    @include background-horizontal(rgba(255, 255, 255, 0), rgba(255, 255, 255, .3));
    @include css3-prefix('transform' , 'skewX(-25deg)');
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.btn-grey {
  @include background-diagonal-tl-br(#A0A0A0, #9E9E9E, #757575, #5E5E5E);
  &:hover {
    @include background-diagonal-tl-br(#5E5E5E, #757575, #9E9E9E, #A0A0A0);
  }
}
.btn-blue {
  @include background-diagonal-tl-br(#5D8AC4, #5C88C1, #4E6FB3, #4964AB);
  &:hover {
    @include background-diagonal-tl-br(#4964AB, #4E6FB3, #5C88C1, #5D8AC4);
  }
}
.btn-red {
  @include background-diagonal-tl-br(#AB6029, #A96329, #A26E30, #9D7735);
  &:hover {
    @include background-diagonal-tl-br(#AB6029, #A96329, #A26E30, #9D7735);
  }
}
.btn-black {
  background: $dark-grey;
  &:hover {
    background: $dark-grey;
  }
}

.btn-light-grey {
  background: #EDEDED;
  &:hover {
    background: #EDEDED;
  }
}
.btn-round {
  min-width: 200px;
  padding: 7px 30px;
  background-color: $base-color;
  color: $white;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @include css3-prefix(border-radius, 20px);
  &:hover {
    background-color: $base-color;
    color: $white;
  }
}
button {
  &:focus {
    outline: none;
  }
}
.btn-border {
  width: auto;
  border: 1px solid $primary-color;
  background-color: transparent;
  color: $primary-color !important;
  &:hover {
    background-color: $primary-color;
    color: $black !important;
  }
}
.btn-lg {
  min-width: 175px;
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 500;
  @media (min-width: 1600px) {
    font-size: 22px;
  }
  @media (max-width: 767px) {
    padding: 6px 15px;
    font-size: 20px;
  }
}

.btn-small {
  min-width: 0;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  @media (max-width: 767px) {
    letter-spacing: 0;
  }
}

.btn-icon {
  img {
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    vertical-align: sub;
  }
}

.input-rc {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 16px;
  vertical-align: middle;
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    + .input-rc-span {
      float: left;
      display: inline-block;
      height: 18px;
      width: 16px;
      margin: 3px 8px 0 0;
      cursor: pointer;
      &::after {
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-top: -2px;
        background-image: url('../images/checkbox.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        cursor: pointer;
        content: '';
      }
    }
    &:checked {
      + .input-rc-span {
        &::after {
          background-image: url('../images/checkbox-check.png');
        }
      }
    }
  }
  input[type='radio'] {
    + .input-rc-span {
      &::after {
        background-image: url('../images/radio.png');
      }
    }
    &:checked {
      + .input-rc-span {
        &::after {
          background-image: url('../images/radio-check.png');
        }
      }
    }
  }
  ~ label {
    vertical-align: middle;
  }
}

/* Input type number custom design */
.quantity {
  position: relative;
  display: inline-block;
  width: 100px;
  .quantity-nav {
    .quantity-button {
      display: inline-block;
      height: 40px;
      width: 30px;
      border: 1px solid $border-grey;
      background-color: #F8F8F8;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.quantity-up {
        float: right;
      }
    }
  }
  input {
    position: absolute;
    top: 0;
    left: 30px;
    height: 40px;
    padding: 2px 5px;
    border: 1px solid $border-grey;
    border-right: none;
    border-left: none;
    @include css3-sufix(width, calc(100% - 60px));
    text-align: center;
    &:focus {
      outline: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      @include css3-prefix(appearance, none);
    }
  }
}
/* End Input type number custom design */

