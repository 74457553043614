.service-ticket-history-wrap {
  padding: 40px 0;
  .service-details {
    .img-wrap {
      position: relative;
      float: left;
      display: inline-block;
      width: 350px;
      margin-right: 40px;
      @media (max-width: 992px) {
        width: auto;
        margin-right: 0;
        margin-bottom: 30px;
      }
      img {
        width: 100%;
        border: 1px solid #E0DDDD;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        height: 100%;
        width: 100%;
        @include background-diagonal-img-effect(rgba($white, .3), rgba($white, .3), transparent, transparent);
        content: '';
      }
    }
    .content-wrap {
      float: left;
      display: inline-block;
      @include css3-sufix(width, calc(100% - 400px));
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 15px;
      color: #282828;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
    }
    p {
      display: inline-block;
      margin-bottom: 30px;
      color: #696969;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .service-history {
    .comments-wrap {
      .comments {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px solid #CCC;
        border-radius: 6px;
        background-color: #F9F9F9;
        &:last-child {
          margin-bottom: 0;
        }
        &.admin {
          .name-wrap {
            float: right;
            margin-left: 30px;
          }
          .user-img {
            float: left;
          }
        }
        &.user {
          .name-wrap {
            float: left;
            margin-right: 30px;
          }
          .user-img {
            float: right;
          }
        }
        .name-wrap {
          display: inline-block;
          @include css3-sufix('width' , 'calc(100% - 130px)');
          .name-date {
            span {
              display: inline-block;
              margin-bottom: 10px;
              font-size: 22px;
            }
            .user-name {
              font-weight: 700;
            }
          }
        }
        .user-img {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
}
