/**
 * Contact us SCSS
 */

/**
 * Contact us SCSS
 */

.contact-way-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  background-image: url('../images/testimonial-bg.png');
  background-size: cover;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .contact-wrap {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    @include css3-prefix(align-items, flex-start);
    width: 100%;
  }
  .contacts-way {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .grid {
      float: left;
      @include css3-sufix(width, calc(50% - 40px));
      margin: 0 20px;
      padding: 30px 10px;
      border-bottom: 2px dotted #B2B2B2;
      text-align: center;
      @media (max-width: 992px) and (min-width: 768px) {
        width: 100%;
        margin: 0;
        border-bottom: none;
      }
      @media (max-width: 480px) {
        width: 100%;
        margin: 0;
        border-bottom: none;
      }
      &:last-child,
      &:nth-child(3) {
        border-bottom: none;
      }
      &:first-child,
      &:nth-child(3) {
        position: relative;
        &::after {
          position: absolute;
          top: 15%;
          right: -20px;
          height: 70%;
          width: 2px;
          border-right: 2px dotted #B2B2B2;
          content: '';
        }
        @media (max-width: 992px) and (min-width: 768px) {
          &::after {
            display: none;
          }
        }
        @media (max-width: 480px) {
          &::after {
            display: none;
          }
        }
      }
      .img-wrap,
      h3 {
        margin-bottom: 15px;
        font-family: 'Poppins', sans-serif;
      }
      h3 {
        color: $primary-color;
        font-size: 16px;
      }
      p {
        margin-bottom: 0;
        line-height: 25px;
      }
      p,
      a {
        color: #696969;
        font-weight: 300;
        font-family: 'Rubik', sans-serif;
      }
    }
  }
  .contact-form-wrap {
    display: inline-block;
    width: 50%;
    padding: 15px 0 0 80px;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
    }
    form {
      display: inline-block;
      width: 100%;
      .form-item {
        display: inline-block;
        width: 100%;
      }
      .form-item,
      .form-text-area {
        margin-bottom: 20px;
        label {
          font-size: 15px;
          font-weight: 400;
          span {
            color: $base-color;
          }
        }
      }
      .form-text-area {
        width: 100%;
      }
      .form-action {
        display: inline-block;
        width: 100%;
        text-align: right;
        .btn {
          font-size: 16px;
          font-weight: 500;
          font-family: 'Rubik', sans-serif;
          letter-spacing: 1px;
          @include css3-prefix(border-radius, 4px);
        }
      }
    }
  }
}

/* Product form design */
.contact-us-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  form {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    width: 100%;
    .form-item {
      @include css3-sufix(display, flex);
      @include css3-prefix(flex-wrap, wrap);
      @include css3-prefix(align-items, flex-end);
      float: left;
      @include css3-sufix(width, calc(33.33% - 20px));
      margin-right: 30px;
      /*&:nth-child(3n) {
        margin-right: 0;
      }*/
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    .form-item,
    .form-text-area {
      margin-bottom: 35px;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        font-size: 15px;
        font-weight: 400;
        word-break: break-all;
      }
      > label {
        display: inline-block;
        width: 100%;
        span {
          color: $base-color;
        }
      }
    }
    .radio-attribute {
      display: inline-block;
      width: 100%;
      label {
        margin-right: 5px;
      }
    }
    .form-text-area {
      width: 100%;
    }
    .form-action {
      display: inline-block;
      width: 100%;
      text-align: left;
      .btn {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Rubik', sans-serif;
        letter-spacing: 1px;
        @include css3-prefix(border-radius, 4px);
      }
    }
  }
  .product-form-details {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    width: 100%;
    .section-title,
    .title-caption {
      max-width: 100%;
      padding: 0;
      text-align: left;
    }
    .section-title {
      margin-bottom: 10px;
    }
    .title-caption {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #DDD;
    }
    .product-form-fielsd {
      float: left;
      display: inline-block;
      width: 75%;
      padding-right: 15px;
      @media (max-width: 992px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .productform-product-details {
      float: left;
      display: inline-block;
      width: 25%;
      padding: 20px;
      border-left: 1px solid #DDD;
      background-color: #EAEAEA;
      /*background-image: url('../images/testimonial-bg.png');
      background-size: cover;*/
      @media (max-width: 992px) {
        width: 100%;
      }
      .product-name {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
      }
      .product-price {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        color: $base-color;
        font-style: 16px;
      }
      .product-image {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .product-description {
        a.btn {
          min-width: 0;
          margin-top: 15px;
          padding: 6px 12px;
          border-radius: 5px;
          font-size: 14px;
        }
      }
    }
  }
  .update-price {
    display: inline-block;
    width: 100%;
  }
  &.quote-wrap {
    form {
      .form-item {
        float: left;
        @include css3-sufix(width, calc(50% - 15px));
        &:nth-of-type(3n) {
          margin-right: 30px;
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        &.form-text-area {
          width: 100%;
          margin-right: 0;
        }
        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

#referform,
#addNewAddress {
  .detail-form {
    padding: 30px 20px 10px;
    .form-item {
      margin-bottom: 25px;
      label {
        font-weight: 500;
      }
    }
    .form-action {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}
