/**
 * Privacy Policy SCSS
 * View cart SCSS
 * Order Conformation SCSS
 * Team SCSS
 * Refer and Earn SCSS
 */

.privacy-policy-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  .privacy-policy-content {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    color: $primary-color;
    h3 {
      margin-bottom: 10px;
      color: $primary-color;
      font-size: 22px;
    }
    ul {
      margin-bottom: 15px;
      padding-left: 20px;
      li {
        list-style: disc;
      }
    }
    p {
      margin-bottom: 20px;
      line-height: 28px;
    }
  }
}

/* View cart page design */
.view-cart-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
}
.cart-services-list.account-table {
  table {
    width: 100%;
    .product-total {
      h6,
      label {
        display: inline-block;
        width: 100%;
      }
      label {
        position: relative;
        padding-right: 40px;
        a {
          position: absolute;
          right: 3px;
          color: $base-color;
          &:hover {
            opacity: .9;
          }
          &:first-child {
            right: 22px;
            color: #696969;
          }
        }
      }
    }
    .detail {
      td {
        vertical-align: top;
      }
    }
    @media (max-width: 992px) {
      min-width: 0;
      thead {
        display: none;
      }
      tr.detail {
        display: inline-block;
        width: 100%;
        padding: 10px;
        background-color: #F5F5F5;
      }
      td {
        display: inline-block;
      }
      .service-img {
        float: right;
      }
      .id {
        @media (max-width: 480px) {
          font-weight: 700;
        }
      }
      td.name {
        width: 100%;
      }
      td.price,
      td.start-date {
        float: left;
        width: 50%;
        span {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    @media (max-width: 480px) {
      td.price,
      td.start-date {
        width: 100%;
      }
    }
  }
  .service-img {
    img {
      width: 100px;
      @media (max-width: 480px) {
        width: 80px;
      }
    }
  }
  .btn-wrap {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    text-align: right;
  }
}
/* End View cart page design */

/* Order Conformation page design */
.order-confo-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  border-top: 2px solid $green-light;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  h1 {
    margin-bottom: 15px;
    @media (max-width: 480px) {
      font-size: 26px;
    }
    img {
      width: 40px;
      margin-right: 10px;
    }
  }
  .delivery-add-wrap {
    display: inline-block;
    width: 100%;
    padding: 20px;
    border: 1px solid $border-grey;
    color: $primary-color;
    h2 {
      font-size: 20px;
    }
    .name {
      font-size: 17px;
    }
    p,
    h4 {
      font-size: 15px;
      font-weight: 400;
    }
    h4 {
      margin-bottom: 15px;
      span {
        font-weight: 500;
      }
    }
    .cart-services-list {
      margin-top: 30px;
    }
  }
}
/* End Order Conformation page design */

/* Our team page design */
.our-team-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 0;
  }
  p {
    display: block;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    color: #7E7E7E;
    font-size: 16px;
    text-align: center;
  }
  .grids {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap);
    width: 100%;
    margin-top: 70px;
    .grid {
      float: left;
      display: inline-block;
      margin-right: 40px;
      margin-bottom: 40px;
      @include css3-prefix('box-shadow' , '0 0 45px 0 #DEDEDE');
      @include css3-sufix('width' , 'calc(33% - 26.3333px)');
      @include css3-prefix('border-radius' , '8px');
      &:nth-child(3n) {
        margin-right: 0;
        @media (max-width: 1024px) {
          margin-right: 40px;
        }
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
      &:nth-child(2n) {
        @media (max-width: 1024px) {
          margin-right: 0;
        }
      }
      &:nth-child(2n+1) {
        @media (max-width: 1024px) {
          clear: left;
        }
      }
      @media (max-width: 1024px) {
        @include css3-sufix('width' , 'calc(50% - 20px)');
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
      .img-wrap {
        .img-round {
          display: block;
          height: 225px;
          width: 225px;
          margin: 20px auto 30px;
          overflow: hidden;
          border: 3px solid #EEEEEF;
          @include css3-prefix('border-radius' , '50%');
          @media (max-width: 992px) {
            margin-bottom: 30px;
          }
        }
      }
      .emp-detail {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;
        padding: 15px 45px;
        text-align: center;
        @media (max-width: 992px) {
          padding: 15px 35px;
        }
        span {
          display: inline-block;
          width: 100%;
        }
        .name {
          margin-bottom: 10px;
          color: $base-color;
          font-size: 20px;
          line-height: normal;
          font-weight: 700;
        }
        .desg {
          color: $primary-color;
          font-size: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }
  }
}
/* End Our team page design */

.table {
  .thead-dark {
    th {
      background-color: $dark-grey;
    }
  }
}

/* Refer and Earn Design */
.refer-wrap-main {
  display: inline-block;
  width: 100%;
  padding: 70px 0;
  .refer-wrap {
    h2 {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      color: $primary-color;
      font-size: 20px;
    }
    h3 {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      color: $base-color;
      font-size: 18px;
    }
    p {
      margin-bottom: 5px;
      color: $primary-color;
      font-size: 16px;
    }
    ul {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      list-style: none;
      li {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 25px;
      }
      &.disc-list {
        list-style: disc;
        list-style-position: inside;
      }
    }
  }
}
/* End Refer and Earn Design */
