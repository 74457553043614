/**
 * Base SCSS
 */

* {
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke: 0;
  @include css3-prefix(text-shadow, none);
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: $primary-color;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  color: $primary-color;
}
ol {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: $base-color;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

img {
  max-width: 100%;
}


