/**
 * Testimonial SCSS
 */

.tesimonial-content-main {
  display: inline-block;
  width: 100%;
  padding: 80px 0 10px;
  @media (max-width: 992px) {
    padding: 40px 0 15px;
  }
  @media (max-width: 767px) {
    padding: 30px 0 10px;
  }
  h2 {
    margin-bottom: 30px;
    color: $primary-color;
    font-size: 23px;
    text-transform: uppercase;
  }
  .grids {
    @include css3-sufix(display, flex);
    @include css3-prefix(flex-wrap, wrap) ;
    width: 100%;
    .grid {
      float: left;
      display: inline-block;
      @include css3-sufix(width, calc(33.33% - 45px));
      margin-right: 67px;
      margin-bottom: 67px;
      padding: 30px;
      border: 1px solid $border-grey;
      background-color: #F6F6F6;
      text-align: center;
      @media (min-width: 768px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix(width, calc(33.33% - 17px));
        margin-right: 25px;
        margin-bottom: 25px;
        padding: 15px;
      }
      @media (max-width: 767px) {
        @include css3-sufix(width, calc(50% - 10px));
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
      .img-wrap {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;
        img {
          height: 80px;
          width: 80px;
          border-radius: 50%;
        }
      }
      .content {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        padding-bottom: 25px;
        font-size: 15px;
        line-height: 25px;
        &::after {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 50%;
          display: inline-block;
          height: 1px;
          width: 80%;
          background-color: rgba($border-grey, .4);
          content: '';
          @include css3-prefix(transform, translateX(-50%));
        }
      }
      .client-detail {
        display: inline-block;
        width: 100%;
        .name {
          color: $primary-color;
          font-size: 20px;
          font-weight: 400;
        }
        .detail {
          color: $green-light;
          font-size: 16px;
        }
      }
    }
  }
}
