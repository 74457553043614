/**
 * login/regstration SCSS
 */

.login-page {
  position: relative;
}
.full-bg-img {
  height: 100vh;
  width: 100%;
  background-image: url('../images/login-bg.jpg');
  background-size: cover;
  @media (max-width: 640px) {
    background: unset;
  }
}
.page-overlay {
  position: fixed;
  @media (max-width: 640px) {
    position: initial;
  }
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: rgba($light-brown, .86);
    content: '';
    @media (max-width: 640px) {
      background-color: unset;
    }
  }
}
.user-form-wrap {
  display: inline-block;
  height: 100vh;
  width: 100%;
  .circle-wrap {
    position: relative;
    float: left;
    display: inline-block;
    height: 100vh;
    width: 50%;
    @media (max-width: 1024px) {
      display: none;
    }
    .circle-inner-line {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      max-height: 400px;
      height: 100%;
      max-width: 400px;
      width: 100%;
      border-radius: 50%;
      background-color: $white;
      text-align: center;
      @include css3-prefix('transform' , 'translate(-50% , -50%)');
      .img-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        max-height: 370px;
        height: 100%;
        max-width: 370px;
        width: 100%;
        padding: 60px;
        border: 1px solid $light-brown;
        border-radius: 50%;
        @include css3-prefix('transform' , 'translate(-50%, -50%)');
        img {
          margin-top: 20px;
        }
      }
    }
  }
  .login-section,
  .reg-section {
    float: right;
    display: inline-block;
    height: 100vh;
    width: 50%;
    overflow: auto;
    background-color: $white;
    @include css3-sufix('display' , 'inline-flex');
    @include css3-prefix('align-items' , 'center');
    @include css3-prefix('justify-content' , 'center');
    @media (max-width: 1024px) {
      width: 100%;
      padding: 30px;
    }
    @media (max-width: 640px) {
      height: auto;
    }
    @media (max-width: 480px) {
      padding: 15px;
    }
    .form-wrap {
      display: inline-block;
      max-height: 100%;
      width: 100%;
      padding: 40px;
      .logo {
        text-align: center;
        h1 {
          margin-top: 40px;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 20px;
          text-align: left;
        }
      }
      .form-item {
        float: left;
        display: inline-block;
        /*width: 100%;*/
        @include css3-sufix(width, calc(50% - 15px));
        margin-bottom: 15px;
        @media (max-width: 480px) {
          width: 100%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        input,
        textarea {
          height: 42px;
          width: 100%;
          border-color: #495057 !important;
          color: #495057;
          font-size: 14px;
          line-height: 21px;
        }
        input[type="radio"] {
          height: 24px;
        }
        &.w-100 {
          width: 100%;
        }
        &.m-r-3 {
          margin-right: 30px;
          @media (max-width: 480px) {
            margin: 0 0 10px;
          }
        }
        &.mobile-otp {
          position: relative;
          text-align: left;
          input {
            @include css3-sufix('width' , 'calc(100% - 125px)');
            @media (max-width: 480px) {
              @include css3-sufix('width' , 'calc(100% - 100px)');
            }
          }
          .btn-otp {
            position: absolute;
            top: 0;
            right: 0;
            height: 42px;
            margin-left: 15px;
            padding: 9px 16px;
            border: none;
            border-radius: 4px;
            background-color: #EE3345;
            color: $white;
            text-transform: uppercase;
            @media (max-width: 480px) {
              padding: 9px 10px;
              font-size: 14px;
            }
          }
        }
      }
      .checkboxs {
        width: 100%;
        text-align: left;
        label {
          float: left;
          display: inline-block;
          width: 50%;
          color: #495057;
          a {
            color: $base-color;
          }
        }
      }
      .form-action {
        float: right;
        display: inline-block;
        margin: 30px 0 20px;
        .btn {
          width: 100%;
          padding: 8px 20px;
          border-radius: 0;
          font-size: 20px;
        }
      }
      .social-login {
        span {
          position: relative;
          display: inline-block;
          width: 100%;
          margin: 20px 0;
          color: $grey-c;
          text-align: center;
          &::before,
          &::after {
            position: absolute;
            top: 50%;
            display: inline-block;
            width: 45%;
            border-bottom: 1px solid $grey-c;
            content: '';
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
        .grids {
          display: inline-block;
          width: 100%;
          margin: 20px 0;
          .grid {
            float: left;
            margin-right: 20px;
            border-radius: 2px;
            text-align: center;
            @include css3-prefix('width' , 'calc(33.33% - 13.3333px)');
            &:nth-child(3n) {
              margin-right: 0;
            }
            @media (max-width: 640px) {
              width: 100%;
              margin: 0 0 10px;
            }
            a {
              position: relative;
              display: inline-block;
              width: 100%;
              padding: 15px;
              color: $white;
              font-family: 'Rubik', sans-serif;
              text-transform: capitalize;
              &::before {
                position: absolute;
                top: 50%;
                left: 0;
                display: inline-block;
                height: 25px;
                width: 25px;
                margin-left: 12px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                @include css3-prefix('transform' , 'translateY(-50%)');
                @media (max-width: 640px) {
                  left: 50%;
                  margin-left: -75px;
                }
              }
              p {
                margin-bottom: 3px;
                font-size: 12px;
                letter-spacing: 1px;
              }
              h2 {
                margin: 0 0 0 20px;
                font-size: 16px;
                font-weight: 500;
                @media (max-width: 640px) {
                  margin: 0;
                }
              }
            }
            &.google {
              background-color: #DB4437;
              a {
                &::before {
                  background-image: url('../images/google-plus-white.svg');
                }
              }
            }
            &.facebook {
              background-color: #4267B2;
              a {
                &::before {
                  background-image: url('../images/facebook-white.svg');
                }
              }
            }
            &.twitter {
              background-color: #38A1F3;
              a {
                &::before {
                  background-image: url('../images/twitter-white.svg');
                }
              }
            }
          }
        }
      }
    }
    .form-links {
      display: inline-block;
      width: 100%;
      margin: 10px 0;
      li {
        float: left;
        display: inline-block;
        width: 45%;
        color: #495057;
        font-size: 14px;
        line-height: 21px;
        font-family: 'Rubik', sans-serif;
        text-align: left;
        @media (max-width: 480px) {
          width: 100%;
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          width: 55%;
          text-align: right;
          a {
            margin-left: 5px;
          }
          @media (max-width: 480px) {
            width: 100%;
            text-align: left;
          }
        }
        a {
          display: inline-block;
          border-bottom: 1px solid #495057;
          color: #495057;
          font-size: 14px;
          line-height: 21px;
          font-family: 'Rubik', sans-serif;
        }
      }
    }
  }
  /*overflow: auto;
  @include css3-sufix('display' , 'inline-flex');
  @include css3-prefix('align-items' , 'center');*/
}




.login-page {
  .form-wrap {
    max-width: 500px;
  }
}
.reg-page {
  .user-form-wrap .form-head-wrap h1 {
    margin-bottom: 30px;
  }
  .form-wrap {
    max-width: 700px;
    @media (max-width: 640px) {
      padding: 0;
    }
    form {
      position: relative;
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }
    .form-step-1,
    .form-step-2 {
      position: absolute;
      top: 0;
      left: 0;
      @include css3-prefix(transition, all .8s);
      &.active {
        position: relative;
        @include css3-prefix(transform, translateX(0));
      }
    }
    .form-step-1 {
      @include css3-prefix(transform, translateX(-100%));
    }
    .form-step-2 {
      @include css3-prefix(transform, translateX(100%));
    }
  }
}
.step-action {
  position: relative;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  text-align: left;
  li {
    display: inline-block;
    &:last-child {
      float: right;
    }
    a.btn {
      min-width: 0;
      padding: 6px 12px;
      font-size: 16px;
      text-transform: capitalize;
      @include css3-prefix(border-radius, 5px);
    }
  }
}
