/**
 * Blog Detail SCSS
 */

.blog-detail-wrap {
  display: inline-block;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 50px 0;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .grids {
    display: inline-block;
    width: 100%;
    .content-wrap {
      float: left;
      display: inline-block;
      @include css3-sufix(width, calc(70% - 40px));
      margin-right: 40px;
      @media (max-width: 992px) {
        width: 100%;
        margin-right: 0;
      }
      .blog-auth-detail {
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;
        padding: 30px 0 20px;
        border-bottom: 1px solid $border-grey;
        li {
          display: inline-block;
          margin-right: 20px;
          img {
            width: 18px;
            margin-right: 5px;
            vertical-align: middle;
          }
          span {
            display: inline-block;
            color: $primary-color;
            font-size: 17px;
            font-weight: 600;
            vertical-align: middle;
          }
        }
      }
      p {
        color: $primary-color;
        font-size: 16px;
        line-height: 26px;
      }
      .comment-form-wrap {
        display: inline-block;
        width: 100%;
        margin-top: 80px;
        @media (max-width: 992px) {
          margin-top: 40px;
        }
        h2 {
          color: #6F6F70;
          font-size: 40px;
          font-weight: 700;
          font-family: 'Rubik', sans-serif;
          @media (max-width: 767px) {
            font-size: 30px;
          }
        }
        p {
          color: #6F6F70;
          font-size: 22px;
          font-family: 'Rubik', sans-serif;
        }
        .card {
          a {
            color: $base-color;
          }
        }
        form {
          display: inline-block;
          width: 100%;
          margin-top: 40px;
          @media (max-width: 992px) {
            margin-top: 20px;
          }
          .form-item {
            float: left;
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 35px;
            @include css3-sufix(width, calc(50% - 17px));
            &.form-textarea,
            &:nth-child(2n) {
              margin-right: 0;
            }
            @media (max-width: 480px) {
              width: 100%;
              margin-right: 0;
            }
            &.form-textarea {
              width: 100%;
            }
          }
          label {
            color: $primary-color;
            font-size: 17px;
            font-weight: 700;
            text-transform: uppercase;
            vertical-align: middle;
          }
          p {
            margin-bottom: 30px;
            font-size: 14px;
            line-height: 25px;
          }
          .form-action {
            .btn {
              font-size: 17px;
              font-weight: 500;
              @include css3-prefix(border-radius, 5px);
            }
          }
        }
      }
    }
    .sidebar {
      float: left;
      display: inline-block;
      width: 30%;
      @media (max-width: 992px) {
        width: 100%;
        margin-top: 40px;
      }
      h3 {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        color: $primary-color;
        font-size: 23px;
        text-transform: uppercase;
        @media (max-width: 992px) {
          margin-bottom: 25px;
        }
      }
      .blog-list {
        display: inline-block;
        width: 100%;
        .list-item {
          float: left;
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .img-wrap {
            float: left;
            display: inline-block;
            width: 90px;
            margin-right: 20px;
            img {
              /*height: 90px;*/
              width: 100%;
              border: 3px solid #E7ECED;
              border-radius: 5px;
              @media (max-width: 480px) {
                @include css3-prefix(box-shadow, 5px 5px 0 $base-color);
              }
            }
          }
          .blog-content {
            float: left;
            display: inline-block;
            @include css3-sufix(width, calc(100% - 110px));
            h4 {
              margin-bottom: 0;
              color: $primary-color;
              font-size: 15px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

