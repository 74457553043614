/**
 * Checkout SCSS
 */

.checkout-steps-wrap {
  display: inline-block;
  width: 100%;
  padding: 60px 0;
  text-align: center;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  .checkout-steps {
    display: inline-block;
    max-width: 860px;
    width: 100%;
    padding-bottom: 60px;
    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
    .bs-wizard-step {
      padding: 0 25px;
      @include css3-sufix(display, flex);
      .step-1,
      .step-2 {
        @include css3-sufix(width, calc(50% - 22.5px));
        @media (max-width: 767px) {
          @include css3-sufix(width, calc(50% - 12px));
        }
      }
      .step {
        @include css3-sufix(display, flex);
        @include css3-prefix(align-items, center);
        .bs-wizard-dot {
          position: relative;
          height: 35px;
          width: 35px;
          background-image: url('../images/Ellipse-grey.png');
          background-position: center;
          background-size: 100% auto;
          @media (max-width: 767px) {
            height: 24px;
            width: 24px;
          }
        }
        .progress {
          height: auto;
          margin: 0 -2px;
          @include css3-sufix(width, calc(100% - 31px));
          border-radius: 0;
          background-color: transparent;
          @media (max-width: 767px) {
            @include css3-sufix(width, calc(100% - 20px));
          }
        }
        .progress-bar {
          height: 7px;
          width: 100%;
          background-color: $dark-grey;
          @media (max-width: 767px) {
            height: 5px;
          }
        }
        &.complete {
          .progress-bar {
            background-color: $green-light;
          }
        }
        &.complete,
        &.active {
          .bs-wizard-dot {
            background-image: url('../images/Ellipse.png');
          }
        }
      }
    }
    .bs-wizard-step-content {
      margin-top: 20px;
      @include css3-sufix(display, flex);
      @include css3-prefix(justify-content, space-between);
      .step {
        display: inline-block;
        text-align: center;
        opacity: .3;
        &.complete,
        &.active {
          opacity: 1;
        }
        .step-text {
          margin-bottom: 0;
          font-size: 17px;
          font-family: 'Rubik', sans-serif;
          @media (max-width: 767px) {
            font-size: 13px;
          }
        }
        .info {
          margin-bottom: 20px;
          color: $primary-color;
          font-size: 18px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 13px;
          }
        }
        img {
          max-width: 70px;
          @media (max-width: 767px) {
            max-width: 40%;
          }
        }
      }
    }
  }
  .address-wrap-main {
    display: inline-block;
    width: 100%;
    .grids {
      float: left;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      @include css3-sufix('width' , 'calc(33.3333% - 13.3333px)');
      &:nth-child(3n) {
        margin-right: 0;
        @media (max-width: 992px) {
          margin-right: 20px;
        }
      }
      &:nth-child(2n) {
        @media (max-width: 992px) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        @include css3-sufix('width' , 'calc(50% - 10px)');
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0 !important;
      }
      .grid {
        float: left;
        display: inline-block;
        width: 100%;
      }
      .address-wrap {
        padding: 15px;
        border: 1px solid #CCC;
        border-bottom: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        h2 {
          font-size: 24px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        p {
          min-height: 50px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 25px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 15px;
          }
        }
      }
      .bg-light-green {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        .btn-wrap,
        .btn-wrap-bottom {
          display: block;
          width: 100%;
          margin: 0 auto;
        }
        .btn-wrap {
          button {
            border-radius: 0;
          }
        }
        .btn-wrap-bottom {
          padding: 6px 20px;
          @include css3-sufix(display, flex);
          @include css3-prefix(justify-content, space-between);
          @media (max-width: 767px) {
            padding: 6px 10px;
          }
          a {
            display: inline-block;
            @include css3-sufix(width, calc(50% - 8px));
            color: $primary-color;
          }
        }
      }
    }
  }
}

.checkout-step1-detail {
  display: inline-block;
  width: 100%;
  border: 1px solid $border-grey;
  h2 {
    display: inline-block;
    width: 100%;
    margin: 17px 0;
    color: $primary-color;
    font-size: 22px;
    text-transform: uppercase;
  }
  .price-wrap {
    display: inline-block;
    width: 100%;
    padding: 20px;
    border-top: 1px solid $border-grey;
    border-bottom: 1px solid $border-grey;
    background-color: $green-light;
    color: $white;
    h3,
    .slash {
      display: inline-block;
      margin-bottom: 0;
      padding: 0 5px;
      font-size: 20px;
      font-weight: 600;
      vertical-align: middle;
      @media (max-width: 767px) {
        padding: 0;
        font-size: 18px;
      }
    }
    .ser {
      padding: 0 30px 0 10px;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    span {
      vertical-align: middle;
    }
    .btn-wrap {
      display: inline-block;
      vertical-align: middle;
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .conformation-info {
    display: inline-block;
    width: 100%;
    padding: 20px 15px;
    background-color: $dark-grey;
    color: $white;
    font-size: 16px;
    font-weight: 600;
  }
}

.checkout-step2-detail {
  display: inline-block;
  width: 100%;
  border: 1px solid $border-grey;
  .detail-form {
    display: inline-block;
    width: 100%;
    h3 {
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px solid $border-grey;
      color: $primary-color;
      font-size: 20px;
      text-transform: uppercase;
    }
    form {
      display: inline-block;
      width: 100%;
      padding: 20px;
      .form-item {
        float: left;
        display: inline-block;
        margin-right: 34px;
        margin-bottom: 20px;
        text-align: left;
        @include css3-sufix(width, calc(50% - 17px));
        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
        }
        label {
          font-weight: 500;
        }
        input {
          height: 40px;
        }
      }
      .form-action {
        display: inline-block;
        width: 100%;
        text-align: right;
        .btn {
          min-width: 116px;
        }
      }
    }
  }
}

.checkout-step3-detail {
  display: inline-block;
  width: 100%;
  border: 1px solid $border-grey;
  text-align: left;
  h2 {
    margin: 0;
    padding: 20px 15px;
    border-bottom: 1px solid $border-grey;
    color: $primary-color;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
  }
  .card-detail-wrap {
    display: inline-block;
    width: 100%;
    padding: 30px;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  .payment-summary-wrap {
    float: right;
    display: inline-block;
    width: 50%;
    padding-left: 50px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 40px;
      padding-left: 0;
    }
    .payment-summary {
      float: right;
      display: inline-block;
      max-width: 300px;
      width: 100%;
      padding: 15px;
      border: 1px solid $border-grey;
      @media (max-width: 767px) {
        float: none;
      }
      h3 {
        color: $primary-color;
        font-size: 22px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      a {
        display: inline-block;
        margin: 10px 0;
        color: $primary-color;
        font-size: 16px;
        font-weight: 600;
      }
      .list-group {
        li {
          .badge {
            float: right;
            padding: 8px 5px 4px;
            font-size: 12px;
          }
        }
      }
      .total {
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        text-align: right;
        span {
          font-size: 15px;
        }
        h4 {
          color: $primary-color;
          font-size: 22px;
        }
      }
    }
  }
  form {
    float: left;
    display: inline-block;
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .form-item,
    .dv {
      margin-bottom: 25px;
      img {
        height: 25px;
        margin-top: 10px;
      }
    }
    label {
      width: 100%;
      font-weight: 500;
    }
    input[type="text"] {
      height: 40px;
      width: 100%;
    }
    .form-action {
      display: inline-block;
      width: 100%;
      .btn {
        min-width: 116px;
      }
    }
    .exp-date {
      > span,
      .item {
        display: inline-block;
        width: 70px;
        vertical-align: middle;
        input[type="text"] {
          width: 100%;
        }
        label {
          font-weight: 400;
        }
      }
      > span {
        width: 50px;
        padding: 33px 0 0;
        text-align: center;
      }
    }
  }
}

/* New checkout process Design */
.checkout-steps-wrap {
  .product-details {
    ul {
      li {
        padding: 10px 15px;
        &.last-child {
          position: relative;
          &::after {
            position: absolute;
            top: auto;
            bottom: 0;
            z-index: 2;
            display: inline-block;
            height: 3px;
            width: 90%;
            margin-bottom: -2px;
            background-color: #DAD9D9;
            content: '';
          }
        }
        .product-name {
          @include css3-sufix(max-width, calc(100% - 100px));
          text-align: left;
          h6 {
            font-size: 18px;
            font-weight: 600;
          }
          h5 {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
          }
        }
        .text-muted {
          width: 100px;
          text-align: right;
        }
        .attribute {
          max-width: 90%;
          width: 100%;
          margin: 0 0 0 auto;
          span:first-child {
            @include css3-sufix(max-width, calc(100% - 100px));
            display: list-item;
            font-size: 12px;
            list-style: disc;
            list-style-position: inside;
            text-align: left;
          }
          span:last-child {
            width: 100px;
            font-size: 12px;
            font-weight: 500;
            text-align: right;
          }
        }
        .total-lable,
        #newtotal {
          color: $base-color;
          font-weight: 600;
        }
      }
    }
    .redeem-wrap {
      input {
        border-radius: 0;
        &:focus {
          border-color: #C8C8C8;
        }
      }
      .btn {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .order-payment {
    form {
      .dv {
        margin-bottom: 15px;
        text-align: left;
        label {
          font-weight: 500;
        }
      }
    }
    .payment-type {
      float: left;
      width: 50%;
      margin-bottom: 0;
      text-align: left;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .delivery-address {
    .list-group-item-heading {
      margin-bottom: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($black, .125);
      text-align: left;
      &:last-child {
        margin: 0;
        padding: 5px;
        border-bottom: none;
      }
      .btn {
        min-width: 0;
        padding: 6px 12px;
      }
      .input-rc input[type='radio']+.input-rc-span {
        margin-top: 0;
      }
    }
    #add-address {
      margin: 0 15px;
    }
  }
  .code_error {
    margin: 2px 0;
    text-align: left;
  }
}

